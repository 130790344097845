import "./App.css";
import React, { useState, useRef, useEffect } from "react";

import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ScrollToTop from "./config/ScrollToTop";
// IMPORT PAGE CLIENTS
import Home from "./Client/pages/Home";
import LoginSignup from "./Client/pages/LoginSignup";
import Sav from "./Client/pages/Sav";
import Service from "./Client/pages/Service";
import WhoWeAre from "./Client/pages/WhoWeAre";
import SingleArticle from "./Client/pages/SingleArticle";
import TermsAndCondition from "./Client/pages/TermsAndCondition";
import ProductByNav from "./Client/pages/ProductByNav";

// IMPORT PAGE BACKOFFICE
import BackOfficeHome from "./BackOffice/pages/BackOfficeHome";

import SavList from "./BackOffice/pages/SavList";
import SavSingle from "./BackOffice/pages/SavSingle";

import CarrouselCreate from "./BackOffice/pages/CarrouselCreate";
import CarrouselItemsAdd from "./BackOffice/pages/CarrouselItemsAdd";
import CarrouselItemsUpdate from "./BackOffice/pages/CarrouselItemsUpdate";
import CarrouselItemList from "./BackOffice/pages/CarrouselItemList";

import CarrouselPartenaire from "./BackOffice/pages/CarrouselPartenaire";
import CarrouselPartenaireList from "./BackOffice/pages/CarrouselPartenaireList";
import CarrouselPartenaireUpdate from "./BackOffice/pages/CarrouselPartenaireUpdate";

import ArticleCreate from "./BackOffice/pages/ArticleCreate";
import ArticlesUpdate from "./BackOffice/pages/ArticlesUpdate";
import ArticleList from "./BackOffice/pages/ArticleList";

import NavigationCreate from "./BackOffice/pages/NavigationCreate";
import NavigationUpdate from "./BackOffice/pages/NavigationUpdate";
import NavigationList from "./BackOffice/pages/NavigationList";

// IMPORT COMPONENTS
import Header from "./Shared/components/Header";
import Footer from "./Shared/components/Footer";
import SEOHelmet from "./Shared/components/SEOHelmet";

import ClientRoutes from "./router/ClientRoutes";
import BackofficeRoutes from "./router/BackOfficeRoutes";

function App() {
  const bodyContainerRef = useRef(null);

  const [userSession, setUserSession] = useState(
    Cookies.get("session_enriquez_menager")
      ? JSON.parse(Cookies.get("session_enriquez_menager"))
      : null
  );

  const setUser = async (token, role) => {
    if (token && role) {
      Cookies.set("session_enriquez_menager", JSON.stringify({ token, role }), {
        expires: 2,
      });
      setUserSession({ token, role });
    } else {
      Cookies.remove("session_enriquez_menager");
      setUserSession(null);
    }
  };

  return (
    <HelmetProvider>
      <Router basename="/">
        <SEOHelmet
          description={"Site web de l'entreprise Enriquez Ménager dans app js"}
        />

        <div className="App" ref={bodyContainerRef}>
          <Header userSession={userSession} setUser={setUser} />

          <ScrollToTop />

          <Routes>
            <Route path="/*" element={<ClientRoutes />} />
            {/* <Route path="/service" element={<Service />} /> */}

            {/* <Route path="/" element={<Home />} />
          <Route path="/sav" element={<Sav />} />
          <Route path="/qui-sommes-nous" element={<WhoWeAre />} />
          <Route path="/termsandcondition" element={<TermsAndCondition />} />

          <Route path="/actu/:single_article_ID" element={<SingleArticle />} />
          <Route path="/nav/:navData" element={<ProductByNav />} /> */}

            <Route
              exact
              path="/admin/login3019"
              element={
                <LoginSignup setUser={setUser} userSession={userSession} />
              }
            />
            {userSession && (
              // <>
              //   <Route path="/backoffice/home" element={<BackOfficeHome />} />

              //   {/************************** SAV CRUD  **************************/}

              //   <Route
              //     path="/backoffice/gestion/sav/list"
              //     element={<SavList />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/sav/single/:savID"
              //     element={<SavSingle />}
              //   />

              //   {/************************** CARROUSEL CRUD  **************************/}
              //   <Route
              //     path="/backoffice/gestion/carrousel/create"
              //     element={<CarrouselCreate />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/carrousel/pictures/add"
              //     element={<CarrouselItemsAdd />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/partenaire/add"
              //     element={<CarrouselPartenaire />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/carrousel/pictures/update/:carrousel_picture_ID"
              //     element={<CarrouselItemsUpdate />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/carrousel/pictures/list"
              //     element={<CarrouselItemList />}
              //   />

              //   {/************************** PARTENAIRE CRUD  **************************/}

              //   <Route
              //     path="/backoffice/gestion/partenaire/add"
              //     element={<CarrouselPartenaire />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/partenaire/list"
              //     element={<CarrouselPartenaireList />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/partenaire/update/:partenaire_ID"
              //     element={<CarrouselPartenaireUpdate />}
              //   />

              //   {/************************** ARTICLES CRUD  **************************/}

              //   <Route
              //     path="/backoffice/gestion/article/create"
              //     element={<ArticleCreate />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/article/update/:article_ID"
              //     element={<ArticlesUpdate />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/article/list"
              //     element={<ArticleList />}
              //   />

              //   {/************************** NAVIGATION CRUD  **************************/}

              //   <Route
              //     path="/backoffice/gestion/navigation/create"
              //     element={<NavigationCreate />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/navigation/update/:navigation_ID"
              //     element={<NavigationUpdate />}
              //   />

              //   <Route
              //     path="/backoffice/gestion/navigation/list"
              //     element={<NavigationList />}
              //   />
              // </>
              <Route path="/backoffice/*" element={<BackofficeRoutes />} />
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>

        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
