import React, { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import CarrouselItemForm from "../components/CarrouselItemForm";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import BackofficeGoBack from "../components/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage";

const CarrouselItemsUpdate = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { itemToUpdate } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  // if (isLoading) return <h1>En cours de chargement</h1>;

  return (
    <main className=" container">
      <SEOHelmet title={"Modification des détails de photos de carrousel"} />

      <BackofficeGoBack
        previousPage={"/carrousel/pictures/list"}
        pageName={"photos"}
      />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Modal show={modalSuccess} onHide={() => setModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modification enregistrée !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Les détails de votre photo ont bien été mis à jour !
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>

      <h1>Modifier une photo du carrousel</h1>
      <CarrouselItemForm
        setIsLoading={setIsLoading}
        setErrorMessage={setErrorMessage}
        submitType="update"
        setModalSuccess={setModalSuccess}
        itemToUpdate={itemToUpdate}
      />
    </main>
  );
};

export default CarrouselItemsUpdate;
