import { Routes, Route } from "react-router-dom";

import BackOfficeHome from "../BackOffice/pages/BackOfficeHome";

import SavList from "../BackOffice/pages/SavList";
import SavSingle from "../BackOffice/pages/SavSingle";

import CarrouselCreate from "../BackOffice/pages/CarrouselCreate";
import CarrouselItemsAdd from "../BackOffice/pages/CarrouselItemsAdd";
import CarrouselItemsUpdate from "../BackOffice/pages/CarrouselItemsUpdate";
import CarrouselItemList from "../BackOffice/pages/CarrouselItemList";

import CarrouselPartenaire from "../BackOffice/pages/CarrouselPartenaire";
import CarrouselPartenaireList from "../BackOffice/pages/CarrouselPartenaireList";
import CarrouselPartenaireUpdate from "../BackOffice/pages/CarrouselPartenaireUpdate";

import ArticleCreate from "../BackOffice/pages/ArticleCreate";
import ArticlesUpdate from "../BackOffice/pages/ArticlesUpdate";
import ArticleList from "../BackOffice/pages/ArticleList";

import NavigationCreate from "../BackOffice/pages/NavigationCreate";
import NavigationUpdate from "../BackOffice/pages/NavigationUpdate";
import NavigationList from "../BackOffice/pages/NavigationList";

const BackofficeRoutes = () => (
  <Routes>
    <Route path="/home" element={<BackOfficeHome />} />

    {/************************** SAV CRUD  **************************/}

    <Route path="/gestion/sav/list" element={<SavList />} />

    <Route path="/gestion/sav/single/:savID" element={<SavSingle />} />

    {/************************** CARROUSEL CRUD  **************************/}
    <Route path="/gestion/carrousel/create" element={<CarrouselCreate />} />

    <Route
      path="/gestion/carrousel/pictures/add"
      element={<CarrouselItemsAdd />}
    />

    <Route path="/gestion/partenaire/add" element={<CarrouselPartenaire />} />

    <Route
      path="/gestion/carrousel/pictures/update/:carrousel_picture_ID"
      element={<CarrouselItemsUpdate />}
    />

    <Route
      path="/gestion/carrousel/pictures/list"
      element={<CarrouselItemList />}
    />

    {/************************** PARTENAIRE CRUD  **************************/}

    <Route path="/gestion/partenaire/add" element={<CarrouselPartenaire />} />

    <Route
      path="/gestion/partenaire/list"
      element={<CarrouselPartenaireList />}
    />

    <Route
      path="/gestion/partenaire/update/:partenaire_ID"
      element={<CarrouselPartenaireUpdate />}
    />

    {/************************** ARTICLES CRUD  **************************/}

    <Route path="/gestion/article/create" element={<ArticleCreate />} />

    <Route
      path="/gestion/article/update/:article_ID"
      element={<ArticlesUpdate />}
    />

    <Route path="/gestion/article/list" element={<ArticleList />} />

    {/************************** NAVIGATION CRUD  **************************/}

    <Route path="/gestion/navigation/create" element={<NavigationCreate />} />

    <Route
      path="/gestion/navigation/update/:navigation_ID"
      element={<NavigationUpdate />}
    />

    <Route path="/gestion/navigation/list" element={<NavigationList />} />
  </Routes>
);

export default BackofficeRoutes;
