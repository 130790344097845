import React, { useState, useEffect } from "react";

import axios from "axios";
import { sanitize } from "dompurify";
import { Link, useNavigate } from "react-router-dom";

import Slider from "react-slick";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import ModalePictureHome from "../../Shared/components/ModalePictureHome";

const Home = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [articleList, setArticleList] = useState([]);
  const [carrouselData, setCarrouselData] = useState();

  const [modalePicture, setModalePicture] = useState(false);

  const settingsSlider = {
    mobileFirst: true,
    dots: true,
    arrows: true,
    autoplay: !modalePicture ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
    ],
    // centerMode: true,
  };

  const fetchHomeData = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      // const url_server = "http://localhost:4000/home/fetch/data";
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/home/fetch/data";
      const response = await axios.get(url_server);

      if (response.data.statut === "OK") {
        setArticleList(response.data.message.articleList);
        setCarrouselData(response.data.message.carrousel);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchHomeData();
  }, []);

  return (
    <main className="home-container container">
      <SEOHelmet tilte={"Acceuil"} />

      <ModalePictureHome modale={modalePicture} setModale={setModalePicture} />

      {errorMessage ? (
        <AlertMessage
          title={errorMessage.title}
          content={errorMessage.content}
          contentColor={"red"}
        />
      ) : null}
      <div
        className="modale-opacity-wrapper"
        style={modalePicture ? { opacity: 0.3 } : {}}>
        <section id="carrousel" className="carrousel-home-container">
          {carrouselData &&
            carrouselData.items &&
            carrouselData.items.length > 0 && (
              <Slider className="carrousel-home" {...settingsSlider}>
                {carrouselData.items.map((item) => {
                  return (
                    <div
                      key={item._id}
                      onClick={() =>
                        item.nav_url === "/" ? setModalePicture(item) : ""
                      }>
                      <a
                        href={item.nav_url !== "/" ? item.nav_url : "#"}
                        target={item.type_item === "odr" ? "_blank" : ""}
                        className="carrousel-link">
                        <img
                          src={item.picture_url}
                          alt={
                            item.title ? item.title : "mise en avant actualité"
                          }
                        />
                      </a>
                    </div>
                  );
                })}
              </Slider>
            )}
        </section>
        <section>
          <h2>Bienvenue chez Enriquez Ménager !</h2>

          <p>
            Enriquez Ménager est votre magasin d'électroménager, cuisine,
            literie, TV, alarme et chauffage à Miramont-de-Guyenne. Chez nous,
            vous trouverez les produits des plus grandes marques au meilleur
            tarif, et surtout un <b>accueil chaleureux</b> et un{" "}
            <b>service efficace</b>.
          </p>
          <p>
            Référents de la réparations d'électroménager et multimédia{" "}
            <b>depuis plus de 35 ans</b>, notre Service Après-Vente se propose
            de vous accompagne dans la préservation de vos appareils, achetés
            chez nous ou non <em>(selon marques et modèles)</em>.
          </p>
        </section>
        <section>
          <h2>Notre actualité</h2>
          <div id="articles">
            {articleList &&
              articleList.length > 0 &&
              articleList
                .sort(
                  (a, b) => new Date(b.posted_date) - new Date(a.posted_date)
                )
                .map((article) => {
                  console.log(article.posted_date);
                  return (
                    <div key={article._id} className="article-card">
                      {article.picture && (
                        <img className="article-image" src={article.picture} />
                      )}
                      <div className="article-body">
                        <h3>{article.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(article.content),
                          }}
                          className="article-text"></div>
                      </div>
                      <button
                        onClick={() => {
                          navigate(`/actu/${article._id}`, {
                            state: { article },
                          });
                        }}
                        className="cc-btn">
                        En savoir plus
                      </button>
                    </div>
                  );
                })}
          </div>
        </section>
        <section id="home-services">
          <h2>Les services Enriquez Ménager</h2>
          <article>
            <h3>Conseil</h3>
            <p>
              Spécialisée depuis plus de 35 ans dans l'équipement de la maison,
              notre équipe est à votre écoute pour vous conseiller au mieux dans
              vos choix de literie, chauffage, électroménager, TV et cuisine.
            </p>
          </article>
          <article>
            <h3>Livraison</h3>

            <p>
              Chez Enriquez Ménager, ce sont nos techniciens qui assurent les
              livraisons ! Ce qui vous garantit une installation optimale et
              sécurisée.
            </p>
            <p>
              Nous vous livrons gratuitement dans un rayon de 20km autour du
              magasin.
            </p>
          </article>
          <article>
            <h3>Après-Vente</h3>
            <p>
              Interne au magasin, notre atelier répare la majorité des marques
              électroménager, TV et hifi.
            </p>
            <p>
              Nos techniciens sont expérimentés et formés en continu par les
              plus grandes marques.
            </p>
          </article>
        </section>
      </div>
    </main>
  );
};

export default Home;
