import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import Login from "../components/Login";
import Signup from "../components/Signup";
import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";

const LoginSignup = ({ setUser, userSession }) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");

  const handleEmptyInput = () => {
    setEmail("");
    setPassword("");
    setUsername("");
    setCheckPassword("");
  };

  if (isLoading) return <div>En cours de chargement ...</div>;

  return (
    <main className="auth-container container">
      <SEOHelmet title={"Authentification"} />

      <div>
        {errorMessage && (
          <AlertMessage
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}

        {modal ? (
          <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setErrorMessage={setErrorMessage}
            setModal={setModal}
            handleEmptyInput={handleEmptyInput}
            setIsLoading={setIsLoading}
            setUser={setUser}
          />
        ) : (
          <Signup
            email={email}
            setEmail={setEmail}
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            checkPassword={checkPassword}
            setCheckPassword={setCheckPassword}
            setErrorMessage={setErrorMessage}
            setModal={setModal}
            handleEmptyInput={handleEmptyInput}
            setIsLoading={setIsLoading}
            setUser={setUser}
          />
        )}
      </div>
    </main>
  );
};

export default LoginSignup;
