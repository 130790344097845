import React, { useState, useEffect } from "react";

import axios from "axios";
import { sanitize } from "dompurify";
import { useLocation, useParams } from "react-router-dom";

import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";

const SingleArticle = () => {
  const { single_article_ID } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [article, setArticle] = useState();

  const fetchArticleData = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/article/single";
      const response = await axios.post(url_server, {
        id: single_article_ID,
      });
      if (response.data.statut === "OK") {
        setArticle(response.data.message);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchArticleData();
  }, []);
  return (
    <main className="article-containern container">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {isLoading ? null : (
        <>
          <SEOHelmet
            title={`Actualité : ${article && article.title}`}
            ogDescription={`[Info 📣] ${article && article.title}`}
            ogImage={article && article.picture ? article.picture : null}
          />

          {article && (
            <>
              {article.picture && <img src={article.picture} alt="" />}
              <h1>{article.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(article.content),
                }}></div>
            </>
          )}
        </>
      )}
    </main>
  );
};

export default SingleArticle;
