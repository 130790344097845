import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import FooterMap from "./FooterMap";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="upper-footer">
        <div className="openning-hours">
          <h2>Nos horaires</h2>
          <p>
            Ouvert du lundi au vendredi
            <br />
            de 9h15 à 12h et de 14h à 19h
            <br />
            et le samedi de 9h à 12h.
          </p>
        </div>
        <div className="footer-contact">
          <h2>Nous contacter</h2>
          <ul>
            <li>
              ZA Bouilhaguet
            </li>
            <li>
              47800 Miramont-de-Guyenne
            </li>
            <li>
              <a href="tel:+33553938767">05 53 93 87 67</a>
            </li>
            <li>
              <a href="mailto:contact@enriquezmenager.fr">
                contact@enriquezmenager.fr
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/enriquezmenager/">Facebook</a>
            </li>
          </ul>
        </div>
        <FooterMap />
      </div>
      <div className="lower-footer">
        <Link to={"/termsandcondition"}>Conditions générales</Link>
        <p>
          Site conçu et réalisé par{" "}
          <a
            href="http://centcommentaire.fr"
            target="_blank"
            id="centcommentaire">
            Cent Commentaire
          </a>
          .
        </p>
      </div>
    </footer >
  );
};

export default Footer;
