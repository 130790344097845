import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BackofficeGoBack from "../components/BackofficeGoBack";

import AlertMessage from "../../Shared/components/AlertMessage";
import ArticlesForm from "../components/ArticlesForm";
import SEOHelmet from "../../Shared/components/SEOHelmet";

const ArticleCreate = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="article-container container">
      <SEOHelmet title={"Création d'article"} />

      <BackofficeGoBack />

      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      <Modal show={modalSuccess} onHide={() => setModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>Nouvel article crée</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="">
        <h1>Créer un article</h1>
        <ArticlesForm
          setErrorMessage={setErrorMessage}
          setIsLoading={setIsLoading}
          setModalSuccess={setModalSuccess}
          from={"add"}
        />
      </div>
    </main>
  );
};

export default ArticleCreate;
