import React from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const Signup = ({
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  checkPassword,
  setCheckPassword,
  setErrorMessage,
  setModal,
  handleEmptyInput,
  setIsLoading,
  setUser,
}) => {
  const navigate = useNavigate();

  const submitSignUpForm = async () => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      if (username && email && password && checkPassword) {
        if (password === checkPassword) {
          const url_server =
            "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/user/signup";

          const response = await axios.post(url_server, {
            email,
            username,
            password,
          });

          if (response.data.statut === "OK") {
            setUser(response.data.token, response.data.role);
            navigate("/backoffice/home");
          } else {
            setErrorMessage({
              title: "Erreur 400",
              content:
                "Vos informations n'ont pas pu être transmises, merci de réessayer",
            });
          }
        } else {
          setErrorMessage({
            title: "Erreur de saisie",
            content:
              "Le mot de passe et sa vérification ne sont pas identiques",
          });
        }
      } else {
        setErrorMessage({
          title: "Erreur de saisie",
          content: `Champ(s) vide(s) :  ${!email && "email"} ${
            !password && "mot de passe"
          }  ${!checkPassword && "vérification de mot de passe"}`,
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };
  return (
    <div className="">
      <h1>Inscrivez-vous</h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitSignUpForm();
        }}>
        <label htmlFor="username">Nom d'utilisateur</label>
        <input
          id="username"
          value={username}
          type="text"
          placeholder="Nom d'utilisateur"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          value={email}
          type="email"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <label htmlFor="password">Mot de passe</label>
        <input
          id="password"
          type="password"
          value={password}
          placeholder="Mot de passe"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <label htmlFor="CheckPassword">Vérification mot de passe</label>
        <input
          type="password"
          id="CheckPassword"
          value={checkPassword}
          placeholder="Vérification mot de passe"
          onChange={(e) => {
            setCheckPassword(e.target.value);
          }}
        />
        <button type="submit">Envoyer</button>
      </form>

      <p className="mt-3">
        Déja un compte ? c'est par{" "}
        <span
          onClick={() => {
            handleEmptyInput();
            setModal(true);
            setErrorMessage("");
          }}>
          ici
        </span>
      </p>
    </div>
  );
};

export default Signup;
