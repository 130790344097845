import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SEOHelmet from "../../Shared/components/SEOHelmet";
import AlertMessage from "../../Shared/components/AlertMessage";
import CarrouselItemForm from "../components/CarrouselItemForm";
import BackofficeGoBack from "../components/BackofficeGoBack";

const CarrouselItemsAdd = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  // if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="container">
      <SEOHelmet title={"Ajout de photo de carrousel"} />

      <BackofficeGoBack />

      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      <Modal show={modalSuccess} onHide={() => setModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Photo bien ajouté au carrousel !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Votre photo à bien été enregistrée</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="">
        <h1>Ajouter une nouvelle photo au carrousel</h1>
        <CarrouselItemForm
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          submitType="add"
          setModalSuccess={setModalSuccess}
        />
      </div>
    </main>
  );
};

export default CarrouselItemsAdd;
