import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import AlertMessage from "../../Shared/components/AlertMessage";
import NavigationForm from "../components/NavigationForm";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import BackofficeGoBack from "../components/BackofficeGoBack";

const NavigationCreate = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="navigation-create-container container">
      <SEOHelmet title={"Création des éléments de navigation"} />

      <BackofficeGoBack />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Modal
        show={modalSuccess}
        onHide={() => {
          setModalSuccess(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Element navigation crée !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Un nouvel onglet de navigation crée</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="">
        <h1>Créer une catégorie parente de navigation de produit</h1>
        <NavigationForm
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          setModalSuccess={setModalSuccess}
          from="create"
        />
      </div>
    </main>
  );
};

export default NavigationCreate;
