import React, { useState, useEffect } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Button, Table } from "react-bootstrap";

import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import BackofficeGoBack from "../components/BackofficeGoBack";
import FilterComponent from "../components/FilterComponent";

const SavList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [savList, setSavList] = useState([]);
  const [savToDisplay, setSavToDisplay] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);

  const deactivate = async (index, element) => {
    try {
      const copySavList = [...savList];

      let indexItemFromDatabase = copySavList.indexOf(element);

      copySavList[indexItemFromDatabase]["statut"] =
        !copySavList[indexItemFromDatabase]["statut"];

      setSavList(copySavList);

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffce/sav/checked";

      const response = await axios.post(url_server, {
        id: copySavList[index]["_id"],
        newStatut: copySavList[indexItemFromDatabase]["statut"],
      });
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  const fetchSav = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/sav/fetch/all";
      const response = await axios.get(url_server);

      if (response.data.statut === "OK" && response.data.message.length > 0) {
        setSavList(response.data.message);

        setSavToDisplay(
          response.data.message.filter((elmt) => elmt.statut === true)
        );

        setTableKeys(Object.keys(response.data.message[0]));
      } else if (response.data.statut === "NOK") {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSav();
  }, []);

  if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="list-container container">
      <SEOHelmet title={"Liste des demandes de contact"} />

      <BackofficeGoBack />

      <FilterComponent
        dataFromFetch={savList}
        dataToDisplay={savToDisplay}
        setDataToDisplay={setSavToDisplay}
        searchInputKeysAvalaible={tableKeys}
      />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {savToDisplay && savToDisplay.length > 0 ? (
        <Table responsive striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Numéro de téléphone</th>
              <th>Email</th>
              <th>Adresse</th>
              <th>Code postal</th>
              <th>Ville</th>
              <th>Sous-garantie</th>
              <th>Famille de produit</th>
              <th>Marque</th>
              <th>Référence</th>
              <th>Numéro de série</th>
              <th>Acheté chez Enriquez Ménager</th>
              <th>Message</th>
              <th>Mémo</th>
              <th>Modifier</th>
              <th>Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {savToDisplay &&
              savToDisplay.length > 0 &&
              savToDisplay.map((sav, index) => {
                return (
                  <tr key={sav._id}>
                    <td>{sav.last_name}</td>
                    <td>{sav.first_name}</td>
                    <td>{sav.phone}</td>
                    <td>{sav.email}</td>
                    <td>{sav.adress}</td>
                    <td>{sav.post_code}</td>
                    <td>{sav.city}</td>
                    <td>{sav.warranty ? "oui" : "non"}</td>
                    <td>{sav.product_type}</td>
                    <td>{sav.brand}</td>
                    <td>{sav.reference}</td>
                    <td>{sav.serial_number}</td>
                    <td>{sav.isEnriquez ? "oui" : "non"}</td>
                    <td>
                      <p
                        className="table-text"
                        style={{
                          overflow: "hidden",
                          // textOverflow: "ellipsis",
                          height: "15vh",
                        }}>
                        {sav.message}
                      </p>
                    </td>
                    <td>{sav.notes}</td>
                    <td>
                      <Button
                        variant="warning"
                        onClick={() => {
                          navigate(
                            `/backoffice/gestion/sav/single/${sav["_id"]}`,
                            { state: { savDetailsFromList: sav } }
                          );
                        }}>
                        Modifier
                      </Button>
                    </td>
                    <td>
                      {sav["statut"] ? (
                        <Button
                          variant="danger"
                          onClick={() => {
                            deactivate(index, sav);
                          }}>
                          Supprimer
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() => {
                            deactivate(index, sav);
                          }}>
                          Réactiver
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <h1>Aucun résultat</h1>
      )}
    </main>
  );
};

export default SavList;
