import React, { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import ArticlesForm from "../components/ArticlesForm";
import AlertMessage from "../../Shared/components/AlertMessage";
import BackofficeGoBack from "../components/BackofficeGoBack";
import SEOHelmet from "../../Shared/components/SEOHelmet";

const ArticlesUpdate = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { dataFromNavigate } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (isLoading) return <h1>En cours de chargement ...</h1>;
  return (
    <main className="container">
      <SEOHelmet title={"Mis à jour des articles"} />
      <BackofficeGoBack
        previousPage={"/article/list"}
        pageName={"des articles"}
      />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <h1>Mis a jour de l'article</h1>

      <ArticlesForm
        setIsLoading={setIsLoading}
        setErrorMessage={setErrorMessage}
        dataFromNavigate={dataFromNavigate}
        from={"update"}
      />
    </main>
  );
};

export default ArticlesUpdate;
