import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import SEOHelmet from "../../Shared/components/SEOHelmet";
import NavigationForm from "../components/NavigationForm";
import BackofficeGoBack from "../components/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage";

const NavigationUpdate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  const { state } = useLocation();

  const { category } = state;

  return (
    <div className="navigation-update-container container">
      <SEOHelmet title={"Mis à jour des éléments de navigation"} />

      <BackofficeGoBack
        previousPage={"/navigation/list"}
        pageName={"éléments de navigation"}
      />

      <h1>Mis à jour de la catégorie {category.title}</h1>

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Modal
        show={modalSuccess}
        onHide={() => {
          setModalSuccess(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Element navigation mis à jour !</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>

      <NavigationForm
        setIsLoading={setIsLoading}
        setErrorMessage={setErrorMessage}
        setModalSuccess={setModalSuccess}
        from="update"
        categoryToUpdate={category}
      />
    </div>
  );
};

export default NavigationUpdate;
