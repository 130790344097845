import React, { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import SEOHelmet from "../../Shared/components/SEOHelmet";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import AlertMessage from "../../Shared/components/AlertMessage";
import BackofficeGoBack from "../components/BackofficeGoBack";

const CarrouselCreate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  const [nameCarrousel, setNameCarrousel] = useState("");
  const [associatedPage, setAssociatedPage] = useState("");

  const handleSubmitCarrouselCreation = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/creation";
      const response = await axios.post(url_server, {
        nameCarrousel,
        associatedPage,
      });

      if (response.data.statut === "OK") {
        setModalSuccess(true);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };
  if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="carrousel-container container">
      <SEOHelmet title={"Création de carrousel"} />

      <BackofficeGoBack />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Modal show={modalSuccess} onHide={() => setModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Carrousel crée !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Votre carrousel à bien été crée</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAssociatedPage("");
              setNameCarrousel("");
              setModalSuccess(false);
            }}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="">
        <h1>Créer un carrousel</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitCarrouselCreation();
          }}>
          <label htmlFor="name">Nom carrousel</label>
          <input
            value={nameCarrousel}
            type="text"
            id="name"
            placeholder="Nom du carrousel"
            onChange={(e) => {
              setNameCarrousel(e.target.value);
            }}
          />

          <label htmlFor="associated-page">Page associée</label>
          <input
            value={associatedPage}
            type="text"
            id="associated-page"
            placeholder="Page associé"
            onChange={(e) => {
              setAssociatedPage(e.target.value);
            }}
          />

          <button type="submit">Envoyer</button>
        </form>
      </div>
    </main>
  );
};

export default CarrouselCreate;
