import React, { useState, useEffect, useRef } from "react";
import logo_entreprise from "../../assets/img/enriquez-menager-tr.png";

import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import {
  motion,
  AnimatePresence,
  useInView,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";

import IconComponent from "./IconComponent";
import AlertMessage from "./AlertMessage";

const Header = ({ userSession, setUser, toggle }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const [searchText, setSearchText] = useState("");
  const [navData, setNavData] = useState([]);

  const [viewPortIsTop, setViewPortIsTop] = useState(true);
  const [displayStickyMenu, setDisplayStickyMenu] = useState({
    y: 0,
    display: false,
  });

  const [viewPortWidth, setViewPortWidth] = useState({
    width: window.innerWidth,
  });

  const [isNavOpen, setIsNavOpen] = useState(true);
  const [productNavIsOpen, setProductNavIsOpen] = useState(false);
  const [productNavPhoneIsOpen, setProductNavPhoneIsOpen] = useState(false);
  const [isFixedNavOpen, setIsFixedNavOpen] = useState(false);
  const [productSidebarIsOpen, setProductSidebarIsOpen] = useState(false);

  const fixedNav = useRef(null);
  const topNav = useRef(null);
  const isTop = useInView(topNav);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest < displayStickyMenu.y) {
      setDisplayStickyMenu({
        y: latest,
        display: true,
      });
    } else {
      setDisplayStickyMenu({
        y: latest,
        display: false,
      });
    }
  });

  const getNavData = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/nav/fetch/all";
      const response = await axios.get(url_server);

      if (response.data.statut === "OK") {
        setNavData(response.data.message);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getNavData();
  }, []);

  const checkForViewPort = () => {
    if (isTop) {
      setProductSidebarIsOpen(false);
      setIsFixedNavOpen(false);
      setViewPortIsTop(true);
    } else {
      setViewPortIsTop(false);
    }
  };

  useEffect(() => {
    checkForViewPort();
  }, [isTop]);

  const updateViewPortWidth = () => {
    setViewPortWidth({ width: window.innerWidth });
  };
  useEffect(() => {
    window.addEventListener("resize", updateViewPortWidth);
    return () => window.removeEventListener("resize", updateViewPortWidth);
  }, [window.innerWidth]);

  return (
    <header ref={topNav} className="header">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <AnimatePresence>
        <motion.div
          className="header-logo"
          onClick={() => {
            navigate("/");
          }}>
          <img src={logo_entreprise} alt="Logo entreprise Enriquez ménager" />
        </motion.div>
      </AnimatePresence>
      <div className="header-right">
        <div className="search-auth-container"></div>
        {userSession && (
          <div id="backoffice-nav">
            <button
              id="backoffice-btn"
              onClick={() => {
                navigate("/backoffice/home");
              }}>
              Backoffice
            </button>
            <button
              className="deconnexion-button"
              onClick={() => {
                setUser(null);
                navigate("/");
              }}>
              Déconnexion
            </button>
          </div>
        )}

        <nav className="nav-pages">
          <ul className="nav-container">
            <li className="nav-item">
              <Link className="nav-link" to={"/qui-sommes-nous"}>
                Qui sommes-nous
              </Link>
            </li>

            <li
              style={{ cursor: "pointer" }}
              className="nav-item"
              onClick={() => setProductNavIsOpen((prevState) => !prevState)}>
              Nos produits
            </li>

            <li className="nav-item">
              <Link className="nav-link" to={"/sav"}>
                Service Après-Vente
              </Link>
            </li>

            <li className="nav-item">
              <a
                className="services-redirect"
                href={"https://www.enriquezservices.fr"}>
                Plomberie / Électricité
              </a>
            </li>
          </ul>
          <div className="product-category-nav">
            <motion.ul
              className="product-category-container"
              initial={productNavIsOpen ? "open" : "closed"}
              animate={productNavIsOpen ? "open" : "closed"}
              variants={{
                open: { height: "auto", opacity: 1 },
                closed: { height: 0, opacity: 0 },
              }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{ overflow: "hidden" }}>
              {navData &&
                navData.length > 0 &&
                navData
                  .sort((a, b) => a.order - b.order)
                  .map((navItem, index) => {
                    return (
                      <li
                        key={index}
                        // whileHover={{ scale: 1.2 }}
                        className="product-category-celui-ci">
                        <a href={`/nav/${navItem.slug}`} className="nav-link">
                          <motion.p
                            variants={{
                              open: { opacity: 1, y: 0 },
                              closed: { opacity: 0, y: -100 },
                            }}
                            whileTap={{ scale: 0.8 }}>
                            {navItem.title}
                          </motion.p>
                        </a>
                      </li>
                    );
                  })}
            </motion.ul>
          </div>
        </nav>

        <div className="nav-phone">
          <AnimatePresence>
            <nav
              id="nav-phone"
              animate={isNavOpen ? "open" : "closed"}
              className="nav-pages-phone">
              <motion.ul
                style={{ overflow: "hidden" }}
                className="nav-container-phone"
                initial={"open"}
                animate={isNavOpen ? "open" : "closed"}
                variants={{
                  open: { height: "auto", opacity: 1 },
                  closed: { height: 0, opacity: 0 },
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}>
                <motion.li
                  className="nav-item-phone"
                  variants={{
                    open: { opacity: 1, x: 0 },
                    closed: { opacity: 0, x: -50 },
                  }}>
                  <Link className="nav-link-phone" to={"/qui-sommes-nous"}>
                    Qui sommes-nous
                  </Link>
                </motion.li>

                <motion.li
                  style={{ cursor: "pointer" }}
                  variants={{
                    open: { opacity: 1, x: 0 },
                    closed: { opacity: 0, x: -50 },
                  }}
                  className="nav-item-phone"
                  onClick={() =>
                    setProductNavPhoneIsOpen((prevState) => !prevState)
                  }>
                  Nos produits
                </motion.li>

                <div className="product-category-nav-phone">
                  <motion.ul
                    className="product-category-container-phone"
                    initial={productNavPhoneIsOpen ? "open" : "closed"}
                    animate={productNavPhoneIsOpen ? "open" : "closed"}
                    variants={{
                      open: { height: "auto", opacity: 1 },
                      closed: { height: 0, opacity: 0 },
                    }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    style={{ overflow: "hidden" }}>
                    {navData &&
                      navData.length > 0 &&
                      navData
                        .sort((a, b) => a.order - b.order)
                        .map((navItem, index) => {
                          if (index % 2 === 0) {
                            return (
                              <motion.li
                                key={index}
                                variants={{
                                  open: { opacity: 1, x: 0 },
                                  closed: { opacity: 0, x: -400 },
                                }}
                                className="product-category">
                                <a
                                  href={
                                    productNavPhoneIsOpen
                                      ? `/nav/${navItem.slug}`
                                      : ""
                                  }
                                  className="nav-link">
                                  <motion.p
                                    variants={{
                                      open: { opacity: 1, y: 0 },
                                      closed: { opacity: 0, y: -100 },
                                    }}
                                    whileTap={{ scale: 0.8 }}>
                                    {navItem.title}
                                  </motion.p>
                                </a>
                              </motion.li>
                            );
                          } else {
                            return (
                              <motion.li
                                key={index}
                                variants={{
                                  open: { opacity: 1, x: 0 },
                                  closed: { opacity: 0, x: 400 },
                                }}
                                className="product-category">
                                <a
                                  href={
                                    productNavPhoneIsOpen
                                      ? `/nav/${navItem.slug}`
                                      : ""
                                  }
                                  className="nav-link">
                                  <motion.p
                                    variants={{
                                      open: { opacity: 1, y: 0 },
                                      closed: { opacity: 0, y: -100 },
                                    }}
                                    whileTap={{ scale: 0.8 }}>
                                    {navItem.title}
                                  </motion.p>
                                </a>
                              </motion.li>
                            );
                          }
                        })}
                  </motion.ul>
                </div>

                <motion.li
                  variants={{
                    open: { opacity: 1, x: 0 },
                    closed: { opacity: 0, x: -50 },
                  }}
                  className="nav-item-phone">
                  <Link className="nav-link-phone" to={"/sav"}>
                    Service Après-Vente
                  </Link>
                </motion.li>

                <motion.li
                  variants={{
                    open: { opacity: 1, x: 0 },
                    closed: { opacity: 0, x: -50 },
                  }}
                  className="nav-item-phone">
                  <a
                    className="services-redirect"
                    href={"https://www.enriquezservices.fr"}>
                    Plomberie / Électricité
                  </a>
                </motion.li>
              </motion.ul>
            </nav>
          </AnimatePresence>
        </div>
      </div>

      <AnimatePresence>
        {!viewPortIsTop && displayStickyMenu.display && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={"nav-isNotTop"}>
            <div className="nav-isNotTop-img-container">
              <img src={logo_entreprise} alt="" />
            </div>
            <IconComponent
              icon="hamburger"
              size={20}
              color="rgb(250,189,3)"
              style={{ cursor: "pointer" }}
              onClick={() => setIsFixedNavOpen((prevState) => !prevState)}
            />
            <AnimatePresence>
              {isFixedNavOpen && (
                <motion.div
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="sidebar">
                  <IconComponent
                    icon="hamburger"
                    size={20}
                    color="#fff"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsFixedNavOpen((prevState) => !prevState)}
                  />
                  <ul>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/qui-sommes-nous"}>
                        Qui sommes-nous
                      </Link>
                    </li>

                    <li
                      style={{ cursor: "pointer" }}
                      className="nav-item"
                      onClick={() =>
                        setProductSidebarIsOpen((prevState) => !prevState)
                      }>
                      Nos produits
                      <AnimatePresence>
                        <motion.ul
                          initial={productSidebarIsOpen ? "open" : "closed"}
                          animate={productSidebarIsOpen ? "open" : "closed"}
                          variants={{
                            open: { height: "auto", opacity: 1 },
                            closed: { height: 0, opacity: 0 },
                          }}
                          transition={{ duration: 0.3, ease: "easeInOut" }}>
                          <AnimatePresence>
                            {navData &&
                              navData.length > 0 &&
                              productSidebarIsOpen &&
                              navData

                                .sort((a, b) => a.order - b.order)
                                .map((navItem, index) => {
                                  return (
                                    <motion.li
                                      key={index}
                                      variants={{
                                        open: { opacity: 1 },
                                        closed: { opacity: 0 },
                                      }}
                                      // whileHover={{ scale: 1.2 }}
                                      whileTap={{ scale: 0.8 }}
                                      className="product-category">
                                      <a
                                        className="nav-link"
                                        href={`/nav/${navItem.slug}`}>
                                        <motion.p
                                          variants={{
                                            open: { opacity: 1, y: 0 },
                                            closed: { opacity: 0, y: -100 },
                                          }}
                                          whileTap={{ scale: 0.8 }}>
                                          {navItem.title}
                                        </motion.p>
                                      </a>
                                    </motion.li>
                                  );
                                })}
                          </AnimatePresence>
                        </motion.ul>
                      </AnimatePresence>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to={"/sav"}>
                        Service Après-Vente
                      </Link>
                    </li>

                    <li className="nav-item">
                      <a
                        className="services-redirect"
                        href={"https://www.enriquezservices.fr"}>
                        Plomberie / Électricité
                      </a>
                    </li>
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
