import React, { useState } from "react";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import BackofficeGoBack from "../components/BackofficeGoBack";

const CarrouselPartenaireUpdate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { partenaireFromNavigate } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataToSend, setDataToSend] = useState(partenaireFromNavigate);

  const [modalSuccess, setModalSuccess] = useState(false);

  const onChangeHande = (key, value) => {
    const copyDataToSend = { ...dataToSend };
    copyDataToSend[key] = value;
    setDataToSend(copyDataToSend);
  };

  const submitUpdatePartenaire = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();

      const keys = Object.keys(dataToSend);

      keys.map((key) => formData.append(key, dataToSend[key]));

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/partenaire/items/update";
      const response = await axios.put(url_server, formData);
      if (response.data && response.data.statut === "OK") {
        setModalSuccess(true);
      } else if (response.data && response.data.statut === "NOK") {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  return (
    <main className="container">
      <SEOHelmet title={"Modification de partenaire"} />

      <BackofficeGoBack
        pageName={"partenaires"}
        previousPage={"/partenaire/list"}
      />
      <h1>Ajout de photo de partenaire</h1>
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Modal show={modalSuccess} onHide={() => setModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Partenaire bien modifié !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Les nouvelles informations ont bien été enreigstrées
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitUpdatePartenaire();
        }}>
        <div className="input-group">
          <label htmlFor="partenaire-add-title">Ajoutez un titre *</label>
          <input
            type="text"
            name="partenaire-add-title"
            id="partenaire-add-title"
            placeholder={partenaireFromNavigate.title}
            onChange={(e) => onChangeHande("title", e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="partenaire-add-link">Ajoutez un lien </label>
          <input
            type="text"
            name="partenaire-add-link"
            id="partenaire-add-link"
            placeholder={partenaireFromNavigate.nav_url}
            onChange={(e) => onChangeHande("nav_url", e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="partenaire-add-picture">Ajoutez une photo </label>
          <input
            type="file"
            name="partenaire-add-picture"
            id="partenaire-add-picture"
            onChange={(e) => onChangeHande("picture", e.target.files[0])}
          />
        </div>

        {partenaireFromNavigate && partenaireFromNavigate.picture_url && (
          <img
            style={{ width: "10%", alignSelf: "center" }}
            src={
              typeof partenaireFromNavigate.picture_url === "string"
                ? partenaireFromNavigate.picture_url
                : URL.createObjectURL(partenaireFromNavigate.picture_url)
            }
            alt=""
          />
        )}

        <button type="submit">Envoyer</button>
      </form>
    </main>
  );
};

export default CarrouselPartenaireUpdate;
