import axios from "axios";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Table, Button } from "react-bootstrap";

import AlertMessage from "../../Shared/components/AlertMessage";
import FilterComponent from "../components/FilterComponent";

const CarrouselPartenaireList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [partenaireItems, setPartenaireItems] = useState([]);
  const [partenaireToDisplay, setPartenaireToDisplay] = useState([]);

  const fetchPartenaireItemsData = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/partenaire/items/list";
      const response = await axios.get(url_server);

      if (response.data && response.data.statut === "OK") {
        // //console.log("OK");
        setPartenaireItems(response.data.message.items);
        setPartenaireToDisplay(
          response.data.message.items.filter((elmt) => elmt.statut === true)
        );
      } else if (response.data && response.data.statut === "NOK") {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  const deactivate = async (index, element) => {
    try {
      const copyPartenaire = [...partenaireItems];

      let indexItemFromDatabase = copyPartenaire.indexOf(element);

      copyPartenaire[indexItemFromDatabase]["statut"] =
        !copyPartenaire[indexItemFromDatabase]["statut"];

      setPartenaireItems(copyPartenaire);

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/partenaire/deactivate";
      const response = await axios.post(url_server, {
        id: copyPartenaire[indexItemFromDatabase]["_id"],
        newStatut: copyPartenaire[indexItemFromDatabase]["statut"],
      });

      if (response.data.statut === "NOK") {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  useEffect(() => {
    fetchPartenaireItemsData();
  }, []);
  return (
    <main className="container">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <FilterComponent
        dataFromFetch={partenaireItems}
        setDataToDisplay={setPartenaireToDisplay}
        dataToDisplay={partenaireToDisplay}
        searchInputKeysAvalaible={["title"]}
      />
      <Table
        responsive
        striped
        bordered
        hover
        variant="dark"
        size="sm"
        id="carrousel-list">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Site associé</th>
            <th>Image</th>
            <th>Modifier</th>
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody>
          {partenaireToDisplay &&
            partenaireToDisplay.length > 0 &&
            partenaireToDisplay.map((partenaire, index) => {
              return (
                <tr key={partenaire.index}>
                  <td>{partenaire.title}</td>
                  <td>
                    <a
                      href={partenaire.nav_url}
                      target="_blank"
                      style={{
                        backgroundColor: "#F9C00E",
                        padding: "2%",
                        border: "none",
                        borderRadius: "5px",
                        color: "#fff",
                        textDecoration: "none",
                      }}>
                      Lien
                    </a>
                    <p>{partenaire.nav_url}</p>
                  </td>
                  <td>
                    <img src={partenaire.picture_url} alt={partenaire.title} />
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => {
                        navigate(
                          `/backoffice/gestion/partenaire/update/${partenaire["_id"]}`,
                          { state: { partenaireFromNavigate: partenaire } }
                        );
                      }}>
                      Modifier
                    </Button>
                  </td>
                  <td>
                    {partenaire.statut ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          deactivate(index, partenaire);
                        }}>
                        Supprimer
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        onClick={() => {
                          deactivate(index, partenaire);
                        }}>
                        Réactiver
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </main>
  );
};

export default CarrouselPartenaireList;
