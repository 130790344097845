import React, { useState, useRef } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

import { Button } from "react-bootstrap";

import CKEditorComponent from "./CKEditorComponent";

const ArticlesForm = ({
  setIsLoading,
  setErrorMessage,
  setModalSuccess,
  dataFromNavigate,
  from,
}) => {
  const navigate = useNavigate();

  const [dataToSend, setDataToSend] = useState(
    dataFromNavigate ? dataFromNavigate : {}
  );

  const onChangeHandle = (value) => {
    const copyObject = { ...dataToSend };
    copyObject.content = value;
    setDataToSend(copyObject);
  };

  const submitArticle = async () => {
    setIsLoading(true);
    setErrorMessage("");
    if (dataToSend.title && dataToSend.content) {
      try {
        const formData = new FormData();

        const keys = Object.keys(dataToSend);

        keys.map((key) => {
          formData.append(key, dataToSend[key]);
        });

        // const url_server = "http://localhost:4000/backoffice/article/create";

        const url_server =
          "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/article/create";

        const response = await axios.post(url_server, formData);

        if (response && response.data) {
          setModalSuccess(true);
        } else {
          setErrorMessage({
            title: "Erreur 400",
            content:
              "Vos informations n'ont pas pu être transmises, merci de réessayer",
          });
        }
      } catch (error) {
        setErrorMessage({
          title: "Erreur 500",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } else {
      setErrorMessage({
        title: "Erreur de saisie",
        content: "Certains champs obligatoires n'ont pas été remplis",
      });
    }

    setIsLoading(false);
  };

  const updateArticle = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();

      const keys = Object.keys(dataToSend);
      keys.map((key) => {
        formData.append(key, dataToSend[key]);
      });

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/article/update";
      const response = await axios.post(url_server, formData);

      if (response.data.statut === "OK") {
        navigate("/backoffice/gestion/article/list");
      } else {
        setErrorMessage({ title: "else", content: "server error again" });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({ title: "catch", content: error.message });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (from === "add") {
            submitArticle();
          } else {
            updateArticle();
          }
        }}>
        <label htmlFor="title">Titre de l'article *</label>
        <input
          value={dataToSend && dataToSend.title ? dataToSend.title : ""}
          type="text"
          id="title"
          placeholder={
            dataFromNavigate ? dataFromNavigate.title : "Titre de l'article"
          }
          onChange={(e) => {
            const copyObject = { ...dataToSend };
            copyObject.title = e.target.value;
            setDataToSend(copyObject);
          }}
        />

        <label htmlFor="content">Contenu de l'article *</label>
        <CKEditorComponent
          onChangeHandle={onChangeHandle}
          updateData={dataFromNavigate}
        />

        <label htmlFor="picture">Photo de l'article</label>
        <input
          type="file"
          id="picture"
          onChange={(e) => {
            const copyObject = { ...dataToSend };
            copyObject.picture = e.target.files[0];
            setDataToSend(copyObject);
          }}
        />
        {dataToSend.picture && (
          <>
            <img
              style={{ width: "15%", alignSelf: "center", margin: "2%" }}
              src={
                typeof dataToSend.picture === "string"
                  ? dataToSend.picture
                  : URL.createObjectURL(dataToSend.picture)
              }
              alt=""
            />
            <Button
              variant="danger"
              onClick={() => {
                const copyObject = { ...dataToSend };
                copyObject.picture = "";
                setDataToSend(copyObject);
              }}>
              Supprimer la photo
            </Button>
          </>
        )}

        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
};

export default ArticlesForm;
