import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "@ckeditor/ckeditor5-HomeMade";

const CKEditorComponent = ({ onChangeHandle, updateData }) => {
  return (
    <CKEditor
      editor={CustomEditor}
      style={{ height: "20vh" }}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "alignment",

            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "uploadImage",
            "blockQuote",
            "|",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: false,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
      }}
      data={
        updateData && updateData.content
          ? updateData.content
          : updateData && updateData.description
          ? updateData.description
          : ""
      }
      onChange={(event, editor) => {
        const data = editor.getData();
        onChangeHandle(data);
      }}
    />
  );
};

export default CKEditorComponent;
