import React, { useState, useRef } from "react";
import axios from "axios";

import { Button } from "react-bootstrap";

import CKEditorComponent from "./CKEditorComponent";

const NavigationForm = ({
  categoryToUpdate,
  setIsLoading,
  setErrorMessage,
  setModalSuccess,
  from,
}) => {
  const editorRef = useRef(null);

  const [parentCategoryDetails, setParentCategoryDetails] = useState(
    categoryToUpdate ? categoryToUpdate : {}
  );

  const onChangeHandle = (value) => {
    const copyObjectParentCategory = { ...parentCategoryDetails };
    copyObjectParentCategory.description = value;
    setParentCategoryDetails(copyObjectParentCategory);
  };

  const submitCategory = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();
      const keys = Object.keys(parentCategoryDetails);

      keys.map((key) => {
        formData.append(key, parentCategoryDetails[key]);
      });

      // const url_server = "http://localhost:4000/backoffice/navigation/create";
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/navigation/create";
      const response = await axios.post(url_server, formData);
      //console.log(response.data);
      if (response.data.statut === "OK") {
        setModalSuccess(true);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  const updateCategory = async () => {
    setErrorMessage("");
    try {
      const formData = new FormData();

      const keys = Object.keys(parentCategoryDetails);
      keys.map((key) => {
        formData.append(key, parentCategoryDetails[key]);
      });

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/navigation/update";
      const response = await axios.post(url_server, formData);

      if (response.data.statut === "OK") {
        setModalSuccess(true);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  const deletePicture = async () => {
    setErrorMessage("");
    try {
      const copyObjectParentCategory = { ...parentCategoryDetails };
      copyObjectParentCategory.picture = "";
      setParentCategoryDetails(copyObjectParentCategory);

      // const url_server =
      //   "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/navigation/picture/delete";

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/navigation/picture/delete";

      const response = await axios.post(url_server, {
        id: parentCategoryDetails._id,
      });

      if (response.data.statut === "OK") {
        alert("Image supprimée");
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (from === "create") {
          submitCategory();
        } else if (from === "update") {
          updateCategory();
        }
      }}>
      <label htmlFor="name">Nom de la catégorie *</label>
      <input
        value={
          parentCategoryDetails && parentCategoryDetails.title
            ? parentCategoryDetails.title
            : ""
        }
        type="text"
        id="name"
        placeholder={
          categoryToUpdate && categoryToUpdate.title
            ? categoryToUpdate.title
            : "Nom de la catégorie"
        }
        onChange={(e) => {
          const copyObjectParentCategory = { ...parentCategoryDetails };
          copyObjectParentCategory.title = e.target.value;
          copyObjectParentCategory.slug = `${e.target.value
            .split(" ")
            .join("-")
            .toLowerCase()}`;
          setParentCategoryDetails(copyObjectParentCategory);
        }}
      />

      <label htmlFor="order">
        Emplacement de la catégorie dans la barre de navigation produit *
      </label>
      <input
        value={parentCategoryDetails.order ? parentCategoryDetails.order : ""}
        type="Number"
        id="order"
        placeholder={
          categoryToUpdate && categoryToUpdate.order
            ? categoryToUpdate.order
            : "Ordre de la catégorie"
        }
        onChange={(e) => {
          const copyObjectParentCategory = { ...parentCategoryDetails };
          copyObjectParentCategory.order = e.target.value;
          setParentCategoryDetails(copyObjectParentCategory);
        }}
      />

      <label htmlFor="desc">Description de la catégorie </label>
      <CKEditorComponent
        onChangeHandle={onChangeHandle}
        updateData={categoryToUpdate}
      />

      <div>
        <label htmlFor="pic">Photo de la catégorie </label>
        <input
          type="file"
          id="pic"
          placeholder={"Photo de présentation de la catégorie"}
          onChange={(e) => {
            const copyObjectParentCategory = { ...parentCategoryDetails };
            copyObjectParentCategory.picture = e.target.files[0];
            setParentCategoryDetails(copyObjectParentCategory);
          }}
        />

        {parentCategoryDetails && parentCategoryDetails.picture && (
          <div>
            <img
              src={
                typeof parentCategoryDetails.picture === "string"
                  ? parentCategoryDetails.picture
                  : URL.createObjectURL(parentCategoryDetails.picture)
              }
              alt="photo de la catégorie de navigation"
              style={{ width: "20%", height: "20%" }}
            />
            <Button variant="danger" onClick={() => deletePicture()}>
              Supprimer la photo
            </Button>
          </div>
        )}
      </div>

      <button type="submit">Envoyer</button>
    </form>
  );
};

export default NavigationForm;
