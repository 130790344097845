import React from "react";

import { Link } from "react-router-dom";
import SEOHelmet from "../../Shared/components/SEOHelmet";
const BackOfficeHome = () => {
  return (
    <main className="backoffice-container container">
      <SEOHelmet title={"Accueil Backoffice"} />

      <h1>Espace gestion</h1>

      <div className="backoffice-cards">
        <ul>
          <li>
            <h2>Carrousel Promo</h2>
            <ul>
              {/* <li>
                <Link to={"/backoffice/gestion/carrousel/create"}>
                  Creation carrousel
                </Link>
              </li> */}

              <li>
                <Link to={"/backoffice/gestion/carrousel/pictures/add"}>
                  Ajouter un élément
                </Link>
              </li>
              <li>
                <Link to={"/backoffice/gestion/carrousel/pictures/list"}>
                  Gestion du carrousel
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li>
            <h2>Partenaires</h2>
            <ul>
              <li>
                <Link to={"/backoffice/gestion/partenaire/add"}>
                  Ajouter un partenaire
                </Link>
              </li>

              <li>
                <Link to={"/backoffice/gestion/partenaire/list"}>
                  Gestion des partenaires
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li>
            <h2>Notre actualité</h2>
            <ul>
              <li>
                <Link to={"/backoffice/gestion/article/create"}>
                  Ajouter un article
                </Link>
              </li>

              <li>
                <Link to={"/backoffice/gestion/article/list"}>
                  Gestion des articles
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li>
            <h2>Service Après-Vente</h2>
            <ul>
              <li>
                <Link to={"/backoffice/gestion/sav/list"}>
                  Gestion des demandes SAV
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <li>
            <h2>Catégories de produits</h2>
            <ul>
              {/* <li>
                <Link to={"/backoffice/gestion/navigation/create"}>
                  Création navigation
                </Link>
              </li> */}

              <li>
                <Link to={"/backoffice/gestion/navigation/list"}>
                  Gestion des catégories
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default BackOfficeHome;
