import React from "react";

import SEOHelmet from "../../Shared/components/SEOHelmet";

const Service = () => {
  return (
    <main>
      <SEOHelmet title={"Nos Services<"} />

      <h1>Services</h1>
    </main>
  );
};

export default Service;
