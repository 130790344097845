import { Routes, Route } from "react-router-dom";

import Home from "../Client/pages/Home";
import LoginSignup from "../Client/pages/LoginSignup";
import Sav from "../Client/pages/Sav";
import Service from "../Client/pages/Service";
import WhoWeAre from "../Client/pages/WhoWeAre";
import SingleArticle from "../Client/pages/SingleArticle";
import TermsAndCondition from "../Client/pages/TermsAndCondition";
import ProductByNav from "../Client/pages/ProductByNav";

const ClientRoutes = () => (
  <Routes>
    <Route path="/service" element={<Service />} />

    <Route path="/" element={<Home />} />
    <Route path="/sav" element={<Sav />} />
    <Route path="/qui-sommes-nous" element={<WhoWeAre />} />
    <Route path="/termsandcondition" element={<TermsAndCondition />} />

    <Route path="/actu/:single_article_ID" element={<SingleArticle />} />
    <Route path="/nav/:navData" element={<ProductByNav />} />
  </Routes>
);

export default ClientRoutes;
