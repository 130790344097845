import axios from "axios";
import React, { useState, useEffect } from "react";

import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BackofficeGoBack from "../components/BackofficeGoBack";
import { sanitize } from "dompurify";

import FilterComponent from "../components/FilterComponent";
import AlertMessage from "../../Shared/components/AlertMessage";

const NavigationList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [navigationCategoryList, setNavigationCategoryList] = useState([]);
  const [categoryToDisplay, setCategoryToDisplay] = useState([]);

  const updateStatutNavigationCategory = async (index, element) => {
    try {
      const copyNavigationList = [...navigationCategoryList];

      let indexItemFromDatabase = copyNavigationList.indexOf(element);

      copyNavigationList[indexItemFromDatabase]["statut"] =
        !copyNavigationList[indexItemFromDatabase]["statut"];

      setNavigationCategoryList(copyNavigationList);

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/navigation/deactivate";

      const response = await axios.post(url_server, {
        id: copyNavigationList[indexItemFromDatabase]["_id"],
        newStatut: copyNavigationList[indexItemFromDatabase]["statut"],
      });
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  const fetchNavigationCategoryList = async () => {
    try {
      // const url_server =
      // "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/navigation/fetch/all";
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/navigation/fetch/all";

      const response = await axios.get(url_server);

      if (response.data.statut === "OK") {
        setNavigationCategoryList(response.data.message);
        setCategoryToDisplay(
          response.data.message.filter((elm) => elm.statut === true)
        );
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  useEffect(() => {
    fetchNavigationCategoryList();
  }, []);

  return (
    <main className="navigation-list-container container">
      <BackofficeGoBack />
      <FilterComponent
        dataFromFetch={navigationCategoryList}
        setDataToDisplay={setCategoryToDisplay}
        dataToDisplay={categoryToDisplay}
        searchInputKeysAvalaible={["title"]}
      />
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <Table responsive striped bordered hover variant="dark" size="sm">
        <thead>
          <tr>
            <th>Nom de catégorie</th>
            <th>URL</th>
            <th>Emplacement</th>
            <th>Description</th>
            <th>Image</th>
            <th>Modifier</th>
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody>
          {categoryToDisplay &&
            categoryToDisplay.length > 0 &&
            categoryToDisplay
              .sort((a, b) => a.order - b.order)
              .map((category, index) => {
                return (
                  <tr key={category._id}>
                    <td>{category.title}</td>
                    <td>{category.url}</td>
                    <td>{category.order}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: sanitize(category.description),
                      }}></td>
                    <td>
                      <img src={category.picture} alt="" />
                    </td>
                    <td>
                      <Button
                        variant="warning"
                        onClick={() => {
                          navigate(
                            `/backoffice/gestion/navigation/update/${category._id}`,
                            {
                              state: { category },
                            }
                          );
                        }}>
                        Modifier
                      </Button>
                    </td>
                    <td>
                      {category["statut"] ? (
                        <Button
                          variant="danger"
                          onClick={() =>
                            updateStatutNavigationCategory(index, category)
                          }>
                          Supprimer
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() =>
                            updateStatutNavigationCategory(index, category)
                          }>
                          Réactiver
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </main>
  );
};

export default NavigationList;
