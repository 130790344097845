import axios from "axios";
import React, { useState, useEffect } from "react";

import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";

const Sav = () => {
  const navigate = useNavigate();

  const productFamily = [
    { slug: "electromenager", value: "Éléctroménager" },
    { slug: "literie", value: "Literie" },
    { slug: "chauffage", value: "Chauffage" },
    { slug: "alarme", value: "Alarme" },
    { slug: "cuisine", value: "Cuisine" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  const [savInfo, setSavInfo] = useState({
    isEnriquez: true,
  });

  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [autoCompleteToSend, setAutoComleteToSend] = useState({
    adress: "",
    cp: "",
    city: "",
  });

  const onChangeHandle = (key, value) => {
    const copyObject = { ...savInfo };
    copyObject[key] = value;
    setSavInfo(copyObject);
  };

  const submitFormSav = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      if (
        savInfo.last_name &&
        savInfo.first_name &&
        savInfo.phone &&
        savInfo.adress &&
        savInfo.isEnriquez
      ) {
        const url_server = "http://localhost:4000/sav/submit";

        // const url_server =
        //   "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/sav/submit";
        const response = await axios.post(url_server, {
          savInfo,
        });

        if (response.data.statut === "OK") {
          setModalSuccess(true);
        } else {
          setErrorMessage({
            title: "Erreur 400",
            content:
              "Vos informations n'ont pas pu être transmises, merci de réessayer",
          });
        }
      } else {
        setErrorMessage({
          title: "Erreur de saisie",
          content: "Un ou plusieurs champs obligatoires ne sont pas renseignés",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  const fetchAutoCompleteData = async () => {
    if (autoCompleteToSend.adress) {
      try {
        const url_server = `https://api-adresse.data.gouv.fr/search/?q=${savInfo.adress
          .split(" ")
          .join("+")}&limit=5`;

        const response = await axios.get(url_server);
        setAutoCompleteData(response.data.features);
      } catch (error) {
        //console.log(error.message);
      }
    }
  };

  useEffect(() => {
    fetchAutoCompleteData();
  }, [autoCompleteToSend]);

  return (
    <main className="sav-container  container">
      <SEOHelmet
        title={"Service Après-vente"}
        description={
          "Demande d’intervention sur appareil électroménager dans les 20km autour de Miramont-de-Guyenne"
        }
        ogDescription={
          "Demande d’intervention sur appareil électroménager dans les 20km autour de Miramont-de-Guyenne"
        }
      />

      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      <Modal
        show={modalSuccess}
        onHide={() => {
          setModalSuccess(false);
          setSavInfo({});
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Demande envoyée !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Votre demande à bien été envoyée à nos services, nous vous
          recontacterons dans les plus brefs délais.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/");
            }}>
            Retourner à la page d'accueil
          </Button>
        </Modal.Footer>
      </Modal>

      <h1>Demande de dépannage</h1>
      <p>Votre appareil est en panne ?</p>
      <p>
        Qu'il soit sous garantie ou non, acheté chez nous ou non{" "}
        <em>(selon marques et modèles)</em>, le SAV Enriquez Ménager est à votre
        service pour vous aider à trouver une solution dans les plus brefs
        délais.
      </p>
      <p>
        Prêt d'appareils <em>(selon disponibilités)</em>, réparations à
        domicile, suivi... Notre équipe mettra tout en œuvre pour vous
        accompagner pendant la panne.
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitFormSav();
        }}>
        <fieldset>
          <div>
            <label htmlFor="lastName">
              Nom <b>*</b>
            </label>
            <input
              value={savInfo.last_name ? savInfo.last_name : ""}
              type="lastName"
              id="lastName"
              placeholder="Nom"
              onChange={(e) => {
                onChangeHandle("last_name", e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="firstName">
              Prénom <b>*</b>
            </label>
            <input
              value={savInfo.first_name ? savInfo.first_name : ""}
              type="first_name"
              id="first_name"
              placeholder="Prénom"
              onChange={(e) => {
                onChangeHandle("first_name", e.target.value);
              }}
            />
          </div>
        </fieldset>
        <fieldset>
          <div>
            <label htmlFor="phone">
              Numéro de téléphone <b>*</b>
            </label>
            <input
              value={savInfo.phone ? savInfo.phone : ""}
              type="phone"
              id="phone"
              placeholder="Numéro de téléphone"
              onChange={(e) => {
                onChangeHandle("phone", e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              value={savInfo.email ? savInfo.email : ""}
              type="email"
              id="email"
              placeholder="Email"
              onChange={(e) => {
                onChangeHandle("email", e.target.value);
              }}
            />
          </div>
        </fieldset>

        <fieldset id="adress-fieldset">
          <div>
            <label htmlFor="adress">
              Adresse <b>*</b>
            </label>
            <input
              value={savInfo.adress ? savInfo.adress : ""}
              type="text"
              id="adress"
              placeholder="Adresse"
              onChange={(e) => {
                const copyAutoCompleteToSend = { ...autoCompleteToSend };
                copyAutoCompleteToSend.adress = e.target.value;
                setAutoComleteToSend(copyAutoCompleteToSend);
                onChangeHandle("adress", e.target.value);
              }}
            />

            {autoCompleteData &&
              autoCompleteData.length > 0 &&
              savInfo.adress && (
                <div className="autocomplete-adress-container">
                  {autoCompleteData.map((data, index) => {
                    return (
                      <p
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          const copySavInfo = { ...savInfo };
                          copySavInfo.adress = data.properties.name;
                          copySavInfo.post_code = data.properties.postcode;
                          copySavInfo.city = data.properties.city;

                          setSavInfo(copySavInfo);

                          setAutoCompleteData([]);
                        }}>
                        {data.properties.label}
                      </p>
                    );
                  })}
                </div>
              )}
          </div>

          <div>
            <label htmlFor="post_code">
              Code postal <b>*</b>
            </label>
            <input
              value={savInfo.post_code ? savInfo.post_code : ""}
              type="text"
              id="post_code"
              placeholder="Code Postal"
              onChange={(e) => {
                onChangeHandle("post_code", e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="city">
              Ville <b>*</b>
            </label>
            <input
              value={savInfo.city ? savInfo.city : ""}
              type="text"
              id="city"
              placeholder="Ville"
              onChange={(e) => {
                onChangeHandle("city", e.target.value);
              }}
            />
          </div>
        </fieldset>

        <fieldset>
          <div>
            <label htmlFor="product_type">Type de produit</label>
            {/* <select
              name="product_type"
              id="product_type"
              onChange={(e) => onChangeHandle("product_type", e.target.value)}>
              <option value="">Choisissez une famille de produit</option>
              {productFamily &&
                productFamily.length > 0 &&
                productFamily.map((family) => {
                  return <option value={family.slug}>{family.value}</option>;
                })}
            </select> */}
            <input
              value={savInfo.product_type ? savInfo.product_type : ""}
              type="product_type"
              id="product_type"
              placeholder="Type de produit"
              onChange={(e) => onChangeHandle("product_type", e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="brand">Marque</label>
            <input
              value={savInfo.brand ? savInfo.brand : ""}
              type="brand"
              id="brand"
              placeholder="Marque"
              onChange={(e) => {
                onChangeHandle("brand", e.target.value);
              }}
            />
          </div>
        </fieldset>

        <fieldset>
          <div>
            <label htmlFor="reference">Référence</label>
            <input
              value={savInfo.reference ? savInfo.reference : ""}
              type="reference"
              id="reference"
              placeholder="Référence"
              onChange={(e) => {
                onChangeHandle("reference", e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="serial_number">Numéro de série</label>
            <input
              value={savInfo.serial_number ? savInfo.serial_number : ""}
              type="serial_number"
              id="serial_number"
              placeholder="Numéro de série"
              onChange={(e) => {
                onChangeHandle("serial_number", e.target.value);
              }}
            />
          </div>
        </fieldset>

        <fieldset>
          <div>
            <label htmlFor="isEnriquez">
              Acheté chez Enriquez <b>*</b>
            </label>

            <select
              name="isEnriquez"
              id="isEnriquez"
              onChange={(e) => {
                onChangeHandle("isEnriquez", e.target.value);
              }}>
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </select>
          </div>

          <div>
            <label htmlFor="warranty">Sous garantie</label>
            <select
              name="warranty"
              id="warranty"
              onChange={(e) => {
                onChangeHandle("warranty", e.target.value);
              }}>
              <option value="">Choisissez une option</option>
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </select>
          </div>
        </fieldset>

        <fieldset id="message-fieldset">
          <div>
            <label htmlFor="message">
              Nature de la panne <b>*</b>
            </label>
            <textarea
              value={savInfo.message ? savInfo.message : ""}
              onChange={(e) => {
                onChangeHandle("message", e.target.value);
              }}
              name="message"
              id="message"
              cols="30"
              rows="10"></textarea>
          </div>
        </fieldset>

        <button type="submit">Envoyer</button>
      </form>
    </main>
  );
};

export default Sav;
