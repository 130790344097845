import React, { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import AlertMessage from "../../Shared/components/AlertMessage";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import BackofficeGoBack from "../components/BackofficeGoBack";

const CarrouselPartenaire = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataToSend, setDataToSend] = useState({
    target: "654033817c1be30219b994a2",
    type_item: "Partenaire",
  });

  const [modalSuccess, setModalSuccess] = useState(false);

  const onChangeHande = (key, value) => {
    const copyDataToSend = { ...dataToSend };
    copyDataToSend[key] = value;
    setDataToSend(copyDataToSend);
  };

  const submitPartenairePicture = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      if (dataToSend.title && dataToSend.picture && dataToSend.target) {
        const formData = new FormData();

        const keys = Object.keys(dataToSend);

        keys.map((key) => {
          //console.log(key, dataToSend[key]);
          formData.append(key, dataToSend[key]);
        });

        const url_server =
          "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/partenaire/add";

        const response = await axios.post(url_server, formData);

        if (
          response.data &&
          response.data.statut &&
          response.data.statut === "OK"
        ) {
          setModalSuccess(true);
        } else {
          setErrorMessage({
            title: "Erreur 400",
            content:
              "Vos informations n'ont pas pu être transmises, merci de réessayer",
          });
        }
      } else {
        setErrorMessage({
          title: "Erreur saisie",
          content: "Des champs obligatoires ne sont pas renseignés",
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  return (
    <main className="container">
      <SEOHelmet title={"Ajout de partenaire"} />

      <BackofficeGoBack />
      <h1>Ajout de photo de partenaire</h1>

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <Modal show={modalSuccess} onHide={() => setModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Partenaire bien ajouté au carrousel !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Votre image à bien été enregistrée</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner au backoffice
          </Button>
        </Modal.Footer>
      </Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitPartenairePicture();
        }}>
        <div className="input-group">
          <label htmlFor="partenaire-add-title">Ajoutez un titre *</label>
          <input
            type="text"
            name="partenaire-add-title"
            id="partenaire-add-title"
            placeholder="Votre titre"
            onChange={(e) => onChangeHande("title", e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="partenaire-add-link">Ajoutez un lien </label>
          <input
            type="text"
            name="partenaire-add-link"
            id="partenaire-add-link"
            placeholder="Votre lien"
            onChange={(e) => onChangeHande("nav_url", e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="partenaire-add-picture">Ajoutez une photo </label>
          <input
            type="file"
            name="partenaire-add-picture"
            id="partenaire-add-picture"
            placeholder="Votre photo"
            onChange={(e) => onChangeHande("picture", e.target.files[0])}
          />
        </div>

        <button type="submit">Envoyer</button>
      </form>
    </main>
  );
};

export default CarrouselPartenaire;
