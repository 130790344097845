import React from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const BackofficeGoBack = ({ previousPage, pageName }) => {
  const navigate = useNavigate();
  return (
    <div className="gestion-header">
      <Button variant="success" onClick={() => navigate("/backoffice/home")}>
        Retour à l'accueil du backoffice
      </Button>

      {previousPage && pageName ? (
        <Button
          variant="primary"
          onClick={() => navigate(`/backoffice/gestion${previousPage}`)}>
          Retour à la liste des {pageName}
        </Button>
      ) : null}
    </div>
  );
};

export default BackofficeGoBack;
