import React, { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import moment from "moment";

import axios from "axios";

const CarrouselItemForm = ({
  setErrorMessage,
  setIsLoading,
  setModalSuccess,
  submitType,
  itemToUpdate,
}) => {
  const [dateInputPlaceholder, setDateInputPlaceholder] = useState({
    debut: "",
    fin: "",
  });
  const [itemDataToSend, setItemDataToSend] = useState(
    itemToUpdate
      ? itemToUpdate
      : {
          target_carrousel: "64f872c062333396117126cc",
        }
  );

  const [isTypeArticle, setIsTypeArticle] = useState(false);
  const [articleList, setArticleList] = useState([]);

  const onChangeHandle = (key, value) => {
    const copyItemToSend = { ...itemDataToSend };
    copyItemToSend[key] = value;
    setItemDataToSend(copyItemToSend);
  };

  const sendNewCarrouselItem = async () => {
    setErrorMessage("");
    setIsLoading(true);
    console.log(itemDataToSend);
    try {
      if (
        itemDataToSend.target_carrousel &&
        itemDataToSend.finishing_date &&
        itemDataToSend.type_item &&
        itemDataToSend.picture_url
      ) {
        if (itemDataToSend.type_item === "article" && itemDataToSend.nav_url) {
          if (
            !itemDataToSend.starting_date ||
            itemDataToSend.starting_date <= Date.now()
          ) {
            itemDataToSend.starting_date = Date.now();
          }
        }

        if (itemDataToSend.finishing_date >= itemDataToSend.starting_date) {
          if (itemDataToSend.type_item === "odr" && !itemDataToSend.pdf) {
            setIsLoading(false);
            setErrorMessage({
              title: "Erreur saisie",
              content: "Il manque le pdf associé à l'odr",
            });
            return;
          }
        } else {
          setErrorMessage({
            title: "Erreur date",
            content:
              "La date de fin est inférieur à la date renseigné ou à la date d'aujourd'hui",
          });
        }

        const formData = new FormData();
        const keys = Object.keys(itemDataToSend);
        keys.map((key) => {
          formData.append(key, itemDataToSend[key]);
        });

        // const url_server =
        //   "http://localhost:4000/backoffice/carrousel/item/add";

        const url_server =
          "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/item/add";

        const response = await axios.post(url_server, formData);

        if (response.data.statut === "OK") {
          setModalSuccess(true);
        } else {
          setErrorMessage({
            title: "Erreur 400",
            content:
              "Vos informations n'ont pas pu être transmises, merci de réessayer",
          });
        }
      } else {
        setErrorMessage({
          title: "Erreur saisie",
          content: "Des champs obligatoires (*) n'ont pas été remplis",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  const updateCarrouselItem = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();
      const keysToSend = Object.keys(itemDataToSend);
      keysToSend.map((key) => {
        formData.append(key, itemDataToSend[key]);
      });
      // const url_server =
      //   "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/items/update";
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/items/update";
      const response = await axios.post(url_server, formData);
      if (response.data.statut === "OK") {
        setModalSuccess(true);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  const fetchArticles = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/articles/fetch/all";
      const response = await axios.get(url_server);
      if (response.data.statut === "OK") {
        setArticleList(response.data.message);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (submitType === "add") {
          sendNewCarrouselItem();
        } else {
          updateCarrouselItem();
        }
      }}>
      <label htmlFor="type">Type d'element *</label>
      <Form.Select
        defaultValue={submitType === "add" ? "" : itemToUpdate.type_item}
        onChange={(e) => {
          onChangeHandle("type_item", e.target.value);
        }}>
        <option value="">Choisissez le type d'élément (odr, actualité)</option>
        <option value="odr">Offre de remboursement</option>
        <option value="actu">Actualité</option>
        <option value="photo">Photo</option>
      </Form.Select>

      {itemDataToSend.type_item === "actu" &&
        articleList &&
        articleList.length > 0 && (
          <>
            <label htmlFor="type">
              Vous devez choisir un article à relier à cette photo
            </label>
            <Form.Select
              defaultValue={
                submitType === "add" ? "" : itemToUpdate.article_ref
              }
              onChange={(e) => {
                onChangeHandle("nav_url", `/article/${e.target.value}`);
                onChangeHandle("article_ref", e.target.value);
              }}>
              <option value="">Choisissez un article</option>
              {articleList.map((article) => {
                return (
                  <option key={article._id} value={article._id}>
                    {article.title}
                  </option>
                );
              })}
            </Form.Select>
          </>
        )}

      <label htmlFor="title">Titre photo *</label>
      <input
        value={
          itemDataToSend && itemDataToSend.title ? itemDataToSend.title : ""
        }
        type="text"
        id="title"
        placeholder={
          submitType === "add" ? "Nom du carrousel" : itemToUpdate.title
        }
        onChange={(e) => {
          onChangeHandle("title", e.target.value);
        }}
      />

      <label htmlFor="description">Description</label>
      <input
        value={
          itemDataToSend && itemDataToSend.description
            ? itemDataToSend.description
            : ""
        }
        type="text"
        id="description"
        placeholder={
          submitType === "add" ? "Description" : itemToUpdate.description
        }
        onChange={(e) => {
          onChangeHandle("description", e.target.value);
        }}
      />

      <label htmlFor="picture">Photo carrousel *</label>
      <input
        type="file"
        id="picture"
        placeholder={
          submitType === "add" ? "Photo carrousel" : itemToUpdate.picture
        }
        onChange={(e) => {
          onChangeHandle("picture_url", e.target.files[0]);
        }}
      />
      {itemDataToSend && itemDataToSend.picture_url && (
        <img
          style={{ width: "10%", alignSelf: "center" }}
          src={
            typeof itemDataToSend.picture_url === "string"
              ? itemDataToSend.picture_url
              : URL.createObjectURL(itemDataToSend.picture_url)
          }
          alt=""
        />
      )}

      {itemDataToSend && itemDataToSend.type_item === "odr" && (
        <>
          <label htmlFor="pdf">Pdf de l'odr *</label>
          <p>{itemToUpdate && itemToUpdate.nav_url}</p>
          <p>{itemDataToSend.pdf && itemDataToSend.pdf["name"]}</p>

          <input
            type="file"
            id="pdf"
            placeholder={submitType === "add" ? "Pdf de l'odr" : null}
            onChange={(e) => {
              onChangeHandle("pdf", e.target.files[0]);
            }}
          />
        </>
      )}

      <label htmlFor="startDisplay">Date début affichage</label>
      {itemToUpdate && itemToUpdate.starting_date && (
        <p>{moment(itemToUpdate.starting_date).format("DD / MM /YYYY")}</p>
      )}

      <input
        value={
          dateInputPlaceholder && dateInputPlaceholder.debut
            ? dateInputPlaceholder.debut
            : ""
        }
        type="date"
        id="startDisplay"
        placeholder={submitType === "add" ? "Date début affichage" : null}
        onChange={(e) => {
          const copyDatePlaceHolder = { ...dateInputPlaceholder };
          copyDatePlaceHolder.debut = e.target.value;
          setDateInputPlaceholder(copyDatePlaceHolder);

          onChangeHandle("starting_date", e.target.valueAsNumber);
        }}
      />

      <label htmlFor="finishing_date">Date fin affichage *</label>
      {itemToUpdate && itemToUpdate.finishing_date && (
        <p>{moment(itemToUpdate.finishing_date).format("DD / MM /YYYY")}</p>
      )}
      <input
        value={
          dateInputPlaceholder && dateInputPlaceholder.fin
            ? dateInputPlaceholder.fin
            : ""
        }
        type="date"
        id="finishing_date"
        placeholder={submitType === "add" ? "Date fin affichage" : null}
        onChange={(e) => {
          const copyDatePlaceHolder = { ...dateInputPlaceholder };
          copyDatePlaceHolder.fin = e.target.value;
          setDateInputPlaceholder(copyDatePlaceHolder);

          onChangeHandle("finishing_date", e.target.valueAsNumber);
        }}
      />

      <button type="submit">Envoyer</button>
    </form>
  );
};

export default CarrouselItemForm;
