import React, { useState, useEffect } from "react";

import axios from "axios";
import { sanitize } from "dompurify";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import AlertMessage from "../../Shared/components/AlertMessage";
import BackofficeGoBack from "../components/BackofficeGoBack";
import SEOHelmet from "../../Shared/components/SEOHelmet";
import FilterComponent from "../components/FilterComponent";

const ArticleList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [modalSuccess, setModalSuccess] = useState(false);

  const [articlesList, setArticlesList] = useState([]);
  const [articleToDisplay, setArticleToDisplay] = useState([]);

  const deactivate = async (index, element) => {
    try {
      const copyArticleList = [...articlesList];

      let indexItemFromDatabase = copyArticleList.indexOf(element);

      copyArticleList[indexItemFromDatabase]["statut"] =
        !copyArticleList[indexItemFromDatabase]["statut"];

      setArticlesList(copyArticleList);

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/article/delete";

      const response = await axios.post(url_server, {
        id: copyArticleList[indexItemFromDatabase]["_id"],
        newStatut: copyArticleList[indexItemFromDatabase]["statut"],
      });
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  const fetchArticles = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/articles/fetch/all";
      const response = await axios.get(url_server);

      if (response.data.statut === "OK") {
        setArticlesList(response.data.message);
        setArticleToDisplay(
          response.data.message.filter((elmt) => elmt.statut === true)
        );
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="list-container container">
      <SEOHelmet title={"Liste des articles"} />
      <BackofficeGoBack />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <FilterComponent
        dataFromFetch={articlesList}
        setDataToDisplay={setArticleToDisplay}
        dataToDisplay={articleToDisplay}
        searchInputKeysAvalaible={["title"]}
      />
      <Table responsive striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Titre</th>
            <th>Contenu</th>
            <th>Photo</th>

            <th>Modifier</th>
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody>
          {articleToDisplay &&
            articleToDisplay.length > 0 &&
            articleToDisplay.map((article, index) => {
              return (
                <tr key={article._id}>
                  <td>{article.title}</td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: sanitize(article.content),
                    }}></td>
                  <td>{article.picture && <img src={article.picture} />}</td>

                  <td>
                    <Button
                      variant="warning"
                      onClick={() => {
                        navigate(
                          `/backoffice/gestion/article/update/${article["_id"]}`,
                          { state: { dataFromNavigate: article } }
                        );
                      }}>
                      Modifier
                    </Button>
                  </td>
                  <td>
                    {article["statut"] ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          deactivate(index, article);
                        }}>
                        Supprimer
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        onClick={() => {
                          deactivate(index, article);
                        }}>
                        Réactiver
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </main>
  );
};

export default ArticleList;
