import React, { useEffect, useState } from "react";

import axios from "axios";
import { sanitize } from "dompurify";
import { useLocation, useParams, use } from "react-router-dom";

import SEOHelmet from "../../Shared/components/SEOHelmet";
import AlertMessage from "../../Shared/components/AlertMessage";

const ProductByNav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [navItemData, setNavItemData] = useState({});

  const { pathname } = useLocation();
  const navCategoryToFetch = pathname.split("/").pop();

  const fetchNavCategoryData = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/nav/fetch/single";
      const response = await axios.post(url_server, { navCategoryToFetch });

      if (response.data.statut === "OK") {
        setNavItemData(response.data.message);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNavCategoryData();
  }, [pathname]);

  return (
    <main className="product-by-category container">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {isLoading ? null : (
        <>
          <SEOHelmet
            title={`Nos produits ${
              navItemData && navItemData.title ? navItemData.title : null
            }`}
          />

          <div className="category-details-container">
            <h1 style={{ textAlign: "center" }}>{navItemData.title}</h1>

            {navItemData.picture && (
              <div className="category-img-container">
                <img src={navItemData.picture} alt="" />
              </div>
            )}
            {navItemData.description && (
              <div
                className="category-description-container"
                dangerouslySetInnerHTML={{
                  __html: sanitize(navItemData.description),
                }}></div>
            )}
          </div>
        </>
      )}
    </main>
  );
};

export default ProductByNav;
