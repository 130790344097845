import React, { useState, useEffect } from "react";

import axios from "axios";
import Slider from "react-slick";

import SEOHelmet from "../../Shared/components/SEOHelmet";
import AlertMessage from "../../Shared/components/AlertMessage";

const WhoWeAre = () => {
  const settingsSlider = {
    mobileFirst: true,
    dots: false,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
    ],
    centerMode: true,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [partenaireData, setPartenaireData] = useState([]);

  const fetchPartenaireCarrousel = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/partenaire/carrousel/fetch";
      const response = await axios.get(url_server);

      //console.log(response.data);
      if (response.data && response.data.statut === "OK") {
        setPartenaireData(response.data.message);
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPartenaireCarrousel();
  }, []);
  return (
    <main className="whoweare container">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <SEOHelmet title={"Qui sommes-nous"} />

      <h1>Qui sommes-nous ?</h1>
      <article>
        <h2>Notre histoire</h2>
        <p>
          Installé au départ dans le garage de ses parents, César Enriquez a
          créé le magasin Enriquez dans la zone du Bouilhaguet à
          Miramont-de-Guyenne en 1987. Ce touche à tout,{" "}
          <em>arrivé d'Espagne à l'âge de 13 ans en 1969</em>, a déjà 7 ans
          d'expérience dans la réparation d'appareils électroménagers lors de
          l'inauguration du magasin.
        </p>
        <p>
          Deux ans plus tard, face à une demande toujours plus variée et
          importante, le magasin devient <b>Enriquez Services</b>, entreprise
          familiale (sa femme l'a rejoint), spécialisée dans l'électroménager et
          le multiservice (avec la plomberie et l'électricité).
        </p>
        <p>
          L'entreprise se développera au fil du temps et permettra à plusieurs
          de ses anciens salariés de reprendre progressivement le flambeau, pour
          créer 3 entités :
          <ul>
            <li>
              <b>Enriquez Ménager</b> par{" "}
              <span className="bold">Mikaël et Xavier</span> (pour
              l'électroménager, la literie, le multimédia, le chauffage, les
              alarmes et la cuisine),
            </li>
            <li>
              <b>Enriquez & Ferboeuf</b> par{" "}
              <span className="bold">Christophe</span> (pour l'électricité, les
              automatismes de portails, l'énergie renouvelable, la VMC),
            </li>
            <li>
              <b>Enriquez Services</b> par{" "}
              <span className="bold">Marielle et Christophe</span> (pour la
              plomberie, le chauffage, le sanitaire, la climatisation, les
              ramonages).
            </li>
          </ul>
        </p>
        <p>
          Aujourd'hui, Enriquez Ménager, c'est un <b>commerce indépendant</b>{" "}
          qui vous conseille sur tout l'électroménager, la literie, le
          multimédia, les poêles à bois et inserts, et la conception de cuisines
          et dressings. C'est également une équipe de techniciens expérimentés
          et formés, à votre service pour les livraisons, réparations et
          dépannages d'appareils électroménagers.
        </p>
      </article>
      <article>
        <h2>Notre savoir-faire</h2>
        <h3>Une équipe de professionnels formés et expérimentés</h3>
        <p>
          Les Ets Enriquez, ce sont avant tout des équipes de professionnels
          passionnés. Ainsi, Mikaël comme Mélanie se feront un plaisir de vous
          apporter leurs <b>connaissances</b> et leur <b>expérience</b> pour
          vous accompagner dans l'équipement de votre maison.
        </p>
        <p>
          De la même manière, Xavier, Édouard et Maxime, nos techniciens SAV,
          sont formés de manière continue à toutes les normes de sécurité{" "}
          <em>(de chauffage, électriques...)</em>, mais également aux standards
          et process de réparation des marques.
        </p>
        <h3>Un service local réactif</h3>
        <p>
          Acheter local, c'est avant tout s'assurer d'avoir une équipe de
          proximité pour bénéficier des meilleurs conseils, ou de l'expertise
          d'un technicien SAV compétent en cas de panne. Depuis l'achat de
          l'appareil jusqu'à la réparation à domicile, équipez au mieux votre
          foyer <b>en toute confiance</b> !
        </p>
        <h3>Des prix compétitifs</h3>
        <p>
          Au fil des années, Enriquez Ménager a noué des partenariats importants
          avec les plus grands acteurs de la literie et de l'électroménager. Le
          fait d'acheter <b>direct usine</b>, de signer des accords avec les
          plus grandes enseignes nous permet de vous proposer un maximum de
          produits aux meilleurs prix, tout en restant indépendants.
        </p>
        <h3>Une implication territoriale importante</h3>
        <p>
          Par l'embauche d'apprentis chaque année et l'accueil de stagiaires,
          nous nous engageons à tout mettre en œuvre pour proposer{" "}
          <b>des partenariats de proximité</b> tout au long de l'année :{" "}
          <em>
            Collège de Miramont, MFR (Maison Familiale Rurale), Pôle Emploi...
          </em>
        </p>
        <p>
          Notre implication passe également par le soutien à de nombreuses
          associations sportives{" "}
          <em>(AS Miramont Lavergne Football, AS Miramont Rugby...)</em>,
          caritatives <em>(Action Cancer 47)</em> et animations culturelles de
          la ville de Miramont-de-Guyenne <em>(Festival des Arts de la Rue)</em>
          .
        </p>
        <p style={{ textAlign: "center" }}>Nos partenaires</p>
      </article>

      <section id="partenaire">
        {partenaireData &&
          partenaireData.items &&
          partenaireData.items.length > 0 && (
            <Slider className="carrousel-partenaire" {...settingsSlider}>
              {partenaireData.items.map((partenaire) => {
                return (
                  <div key={partenaire._id}>
                    <a
                      href={partenaire.nav_url}
                      target={"_blank"}
                      className="carrousel-link">
                      <img
                        // style={{ width: "100%" }}
                        src={partenaire.picture_url}
                        alt={partenaire.title}
                      />
                    </a>
                  </div>
                );
              })}
            </Slider>
          )}
      </section>
    </main>
  );
};

export default WhoWeAre;
