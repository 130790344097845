import React from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = ({
  email,
  setEmail,
  password,
  setPassword,
  setErrorMessage,
  setModal,
  handleEmptyInput,
  setIsLoading,
  setUser,
}) => {
  const navigate = useNavigate();

  const submitLoginForm = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/user/login";

      const response = await axios.post(url_server, {
        email,
        password,
      });

      if (response.data.statut === "OK") {
        setUser(response.data.token, response.data.role);

        navigate("/backoffice/home");
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    handleEmptyInput();
    setIsLoading(false);
  };

  return (
    <div className="">
      <h1>Connectez-vous</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitLoginForm();
        }}>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          type="email"
          id="email"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <label htmlFor="password">Mot de passe</label>
        <input
          type="password"
          id="password"
          value={password}
          placeholder="Mot de passe"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
};

export default Login;
