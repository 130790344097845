import React, { useState } from "react";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import BackofficeGoBack from "../components/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage";

const SavSingle = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { savDetailsFromList } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [modalSuccess, setModalSuccess] = useState(false);

  const productFamily = [
    { slug: "electromenager", value: "Éléctroménager" },
    { slug: "literie", value: "Literie" },
    { slug: "chauffage", value: "Chauffage" },
    { slug: "alarme", value: "Alarme" },
    { slug: "cuisine", value: "Cuisine" },
  ];

  const [savDetailsToSend, setSavDetailsToSend] = useState(savDetailsFromList);

  const onChangeInputHandle = (e) => {
    const copySavToSend = { ...savDetailsToSend };
    copySavToSend[e.target.name] = e.target.value;
    setSavDetailsToSend(copySavToSend);
  };

  const submitUpdateSav = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      // const url_server = "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/sav/update";
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/sav/update";

      const response = await axios.post(url_server, savDetailsToSend);
      if (response.data && response.data.statut === "OK") {
        setModalSuccess(true);
      }
    } catch (error) {
      //console.log(error.message);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  return (
    <main className="sav-single-container container">
      <BackofficeGoBack
        previousPage={"/sav/list"}
        pageName={"demandes de SAV"}
      />

      <h1>Détails demande de SAV</h1>
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <Modal
        show={modalSuccess}
        onHide={() => {
          setModalSuccess(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Mis à jour des informations sav réussie !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Mis à jour effectuée</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              navigate("/backoffice/gestion/sav/list");
            }}>
            Retourner à la liste des demandes de sav
          </Button>
          <Button
            variant="success"
            onClick={() => {
              navigate("/backoffice/home");
            }}>
            Retourner à la page d'accueil du backoffice
          </Button>
        </Modal.Footer>
      </Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitUpdateSav();
        }}>
        <div className="sav-single-input-group">
          <label htmlFor="last_name">Nom</label>
          <input
            type="text"
            name="last_name"
            placeholder={savDetailsFromList.last_name}
            value={savDetailsToSend.last_name ? savDetailsToSend.last_name : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="first_name">Prénom</label>
          <input
            type="text"
            name="first_name"
            placeholder={savDetailsFromList.first_name}
            value={
              savDetailsToSend.first_name ? savDetailsToSend.first_name : ""
            }
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="phone">Numéro de téléphone</label>
          <input
            type="text"
            name="phone"
            placeholder={savDetailsFromList.phone}
            value={savDetailsToSend.phone ? savDetailsToSend.phone : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            placeholder={savDetailsFromList.email}
            value={savDetailsToSend.email ? savDetailsToSend.email : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="adress ">Adresse</label>
          <input
            type="text"
            name="adress"
            placeholder={savDetailsFromList.adress}
            value={savDetailsToSend.adress ? savDetailsToSend.adress : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="post_code ">Code Postal</label>
          <input
            type="text"
            name="post_code"
            placeholder={savDetailsFromList.post_code}
            value={savDetailsToSend.post_code ? savDetailsToSend.post_code : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="city ">Ville</label>
          <input
            type="text"
            name="city"
            placeholder={savDetailsFromList.city}
            value={savDetailsToSend.city ? savDetailsToSend.city : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="warranty ">Garantie</label>
          <select
            name="warranty"
            onChange={(e) => onChangeInputHandle(e)}
            defaultValue={savDetailsFromList.warranty}>
            <option value={true}>Oui</option>
            <option value={false}>Non</option>
          </select>
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="product_type ">Famille de produit</label>
          <select
            name="product_type"
            defaultValue={savDetailsToSend.product_type}
            onChange={(e) => onChangeInputHandle(e)}>
            {productFamily &&
              productFamily.length > 0 &&
              productFamily.map((family, index) => {
                return (
                  <option key={index} value={family.slug}>
                    {family.value}
                  </option>
                );
              })}
          </select>
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="brand ">Marque</label>
          <input
            type="text"
            name="brand"
            placeholder={savDetailsFromList.brand}
            value={savDetailsToSend.brand ? savDetailsToSend.brand : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="reference ">Référence</label>
          <input
            type="text"
            name="reference"
            placeholder={savDetailsFromList.reference}
            value={savDetailsToSend.reference ? savDetailsToSend.reference : ""}
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="serial_number ">Numéro de série</label>
          <input
            type="text"
            name="serial_number"
            placeholder={savDetailsFromList.serial_number}
            value={
              savDetailsToSend.serial_number
                ? savDetailsToSend.serial_number
                : ""
            }
            onChange={(e) => {
              onChangeInputHandle(e);
            }}
          />
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="isEnriquez">Acheté chez Enriquez Ménager</label>
          <select
            name="isEnriquez"
            defaultValue={savDetailsFromList.isEnriquez}
            onChange={(e) => onChangeInputHandle(e)}>
            <option value={true}>Oui</option>

            <option value={false}>Non</option>
          </select>
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            cols="30"
            rows="10"
            readOnly
            value={savDetailsFromList.message}></textarea>
        </div>

        <div className="sav-single-input-group">
          <label htmlFor="notes">Mémo</label>

          <textarea
            cols={30}
            rows={10}
            onChange={(e) => {
              const copyState = { ...savDetailsToSend };
              copyState["notes"] = e.target.value;
              setSavDetailsToSend(copyState);
            }}
            value={savDetailsToSend.notes}
            id="notes"
            name="notes"
          />
        </div>

        <button type="submit">Envoyer</button>
      </form>
    </main>
  );
};

export default SavSingle;
