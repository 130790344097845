import React from "react";

import { motion, AnimatePresence } from "framer-motion";
import IconComponent from "./IconComponent";

const AlertMessage = ({ errorMessage, setErrorMessage }) => {
  const variants = {
    dislpay: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <ul className="information-message-ul">
      <AnimatePresence initial={false}>
        <motion.div
          animate={errorMessage ? "dislpay" : "hidden"}
          variants={variants}>
          <div className="information-message-container">
            <div
              className="information-message-close-container"
              onClick={() => {
                setErrorMessage("");
              }}>
              <IconComponent
                icon="close-filled"
                size={20}
                color="#fff"
                style={{ cursor: "pointer" }}
                onClick={() => setErrorMessage("")}
              />
            </div>
            {errorMessage && errorMessage.title && (
              <p className="information-message-title">{errorMessage.title}</p>
            )}
            {errorMessage && errorMessage.content && (
              <p className="information-message-content">
                {errorMessage.content}
              </p>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </ul>
  );
};

export default AlertMessage;
