import React, { useState, useEffect } from "react";

import axios from "axios";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import SEOHelmet from "../../Shared/components/SEOHelmet";
import AlertMessage from "../../Shared/components/AlertMessage";
import BackofficeGoBack from "../components/BackofficeGoBack";
import FilterComponent from "../components/FilterComponent";

const CarrouselItemList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [modalSuccess, setModalSuccess] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [itemToDisplay, setItemToDisplay] = useState([]);

  const deactivate = async (index, element) => {
    try {
      const copyItemArray = [...itemList];

      let indexItemFromDatabase = copyItemArray.indexOf(element);

      copyItemArray[indexItemFromDatabase]["statut"] =
        !copyItemArray[indexItemFromDatabase]["statut"];

      setItemList(copyItemArray);

      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/item/delete";
      const response = await axios.post(url_server, {
        id: copyItemArray[indexItemFromDatabase]["_id"],
        newStatut: copyItemArray[index]["statut"],
      });

      if (response.data.statut === "NOK") {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
  };

  const fetchCarrouselItemList = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url_server =
        "https://enriquezmenager-back-423dfadc5b9c.herokuapp.com/backoffice/carrousel/items/fetch/all";
      const response = await axios.get(url_server);

      if (response.data.statut === "OK") {
        setItemList(response.data.message.items);
        setItemToDisplay(
          response.data.message.items.filter(
            (element) => element.statut === true
          )
        );
      } else {
        setErrorMessage({
          title: "Erreur 400",
          content:
            "Vos informations n'ont pas pu être transmises, merci de réessayer",
        });
      }
    } catch (error) {
      setErrorMessage({
        title: "Erreur 500",
        content:
          "Vos informations n'ont pas pu être transmises, merci de réessayer",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCarrouselItemList();
  }, []);

  if (isLoading) return <h1>En cours de chargement ...</h1>;

  return (
    <main className="list-container container">
      <SEOHelmet title={"Liste des photos de carrousel"} />

      <BackofficeGoBack />

      <FilterComponent
        dataFromFetch={itemList}
        setDataToDisplay={setItemToDisplay}
        dataToDisplay={itemToDisplay}
        searchInputKeysAvalaible={["title"]}
      />

      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {itemToDisplay && itemToDisplay.length > 0 ? (
        <Table
          responsive
          striped
          bordered
          hover
          variant="dark"
          size="sm"
          id="carrousel-list">
          <thead>
            <tr>
              <th>Titre</th>
              <th>Description</th>
              <th>Photo</th>

              <th>URL</th>
              <th>Type associé</th>
              <th>Date de début d'affichage</th>
              <th>Date de fin d'affichage</th>
              <th>Statut</th>

              <th>Modifier</th>
              <th>Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {itemToDisplay &&
              itemToDisplay.length > 0 &&
              itemToDisplay
                .sort((a, b) => a.starting_date - b.starting_date)
                .map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      <td>
                        <img src={item.picture_url} alt="" />
                      </td>
                      <td>
                        <Link to={item.nav_url} target="_blank">
                          Se rendre sur la page associé à la photo
                        </Link>
                      </td>

                      <td>{item.type_item}</td>
                      <td>
                        {moment(item["starting_date"]).format("DD / MM / YYYY")}
                      </td>
                      <td>
                        {moment(item["finishing_date"]).format(
                          "DD / MM / YYYY"
                        )}
                      </td>

                      <td>{item.statut ? "activée" : "désactivée"}</td>

                      <td>
                        <Button
                          variant="warning"
                          onClick={() => {
                            navigate(
                              `/backoffice/gestion/carrousel/pictures/update/${item["_id"]}`,
                              { state: { itemToUpdate: item } }
                            );
                          }}>
                          Modifier
                        </Button>
                      </td>
                      <td>
                        {item["statut"] ? (
                          <Button
                            variant="danger"
                            onClick={() => {
                              deactivate(index, item);
                            }}>
                            Supprimer
                          </Button>
                        ) : (
                          <Button
                            variant="success"
                            onClick={() => {
                              deactivate(index, item);
                            }}>
                            Réactiver
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      ) : (
        <h1>Ajoutez des éléments au carrousel pour les voir s'afficher ici</h1>
      )}
    </main>
  );
};

export default CarrouselItemList;
