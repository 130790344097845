import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import IconComponent from "../components/IconComponent";

const ModalePictureHome = ({ modale, setModale }) => {
  console.log(modale);
  const variants = {
    active: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <AnimatePresence initial={false}>
      <motion.div
        id="modal-picture-home"
        animate={modale ? "active" : "hidden"}
        variants={variants}>
        <div className="close-container">
          <IconComponent
            icon="close-filled"
            size={20}
            color="#db2d32"
            style={{ cursor: "pointer" }}
            onClick={() => setModale(false)}
          />
        </div>
        <div className="home-picture-container">
          <img src={modale.picture_url ? modale.picture_url : ""} alt="" />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ModalePictureHome;
