import React, { useState, useEffect } from "react";

import IconComponent from "../../Shared/components/IconComponent";

const FilterComponent = ({
  dataToDisplay,
  setDataToDisplay,
  dataFromFetch,
  searchInputKeysAvalaible,
}) => {
  const [filter, setFilter] = useState({
    activ: true,
  });

  const filterResultsHandle = (listToFilter) => {
    let filteredResults = listToFilter;

    if (Object.keys(filter).length > 0) {
      if (filter.activ) {
        filteredResults = filteredResults.filter(
          (elmt) => elmt.statut === true
        );
      }
      if (filter.deactivated) {
        filteredResults = filteredResults.filter(
          (elmt) => elmt.statut === false
        );
      }

      if (filter.deactivated && filter.activ) {
        filteredResults = listToFilter;
      }

      if (
        filter["searchInput"] &&
        searchInputKeysAvalaible &&
        searchInputKeysAvalaible.length > 0
      ) {
        let arrayCorrespondance = [];
        searchInputKeysAvalaible.map((key) => {
          filteredResults.map((result) => {
            if (typeof result[key] === "string") {
              if (
                result[key]
                  .toLowerCase()
                  .includes(filter["searchInput"].toLowerCase()) &&
                !arrayCorrespondance.find((elmt) => elmt === result)
              ) {
                arrayCorrespondance.push(result);
              }
            }
          });
        });
        filteredResults = arrayCorrespondance;
      }

      setDataToDisplay(filteredResults);
    } else {
      filteredResults = [];

      setDataToDisplay(dataFromFetch);
    }
  };

  const filterChangeHandle = (key, value) => {
    const copyFilter = { ...filter };
    if (value) {
      copyFilter[key] = value;
    } else {
      delete copyFilter[key];
    }
    setFilter(copyFilter);
  };

  useEffect(() => {
    filterResultsHandle(dataFromFetch);
  }, []);

  useEffect(() => {
    filterResultsHandle(dataFromFetch);
  }, [filter]);

  useEffect(() => {
    filterResultsHandle(dataToDisplay);
  }, [dataFromFetch]);

  return (
    <div className="filter-container">
      <div className="filter-search-input-container">
        <h3>Rechercher</h3>
        <div className="search-input-icon-container">
          <input
            type="text"
            name="filter-text-search"
            id="filter-text-search"
            placeholder="Commencez à taper"
            onChange={(e) => {
              filterChangeHandle("searchInput", e.target.value);
            }}
          />

          <IconComponent
            icon="search"
            size={20}
            color="#db2d32"
            style={{ cursor: "pointer" }}
            onClick={() => filterResultsHandle(dataToDisplay)}
          />
        </div>
      </div>
      <div className="filter-statut">
        <h3>Statut</h3>
        <div>
          <div>
            <input
              type="checkbox"
              name="carrousel-statut-activ"
              id="carrousel-statut-activ"
              // disabled={filter.deactivated ? true : false}
              checked={filter.activ ? true : false}
              onChange={(e) => filterChangeHandle("activ", e.target.checked)}
            />
            <label htmlFor="carrousel-statut-activ">Activés</label>
          </div>

          <div>
            <input
              type="checkbox"
              name="carrousel-statut-deactivate"
              id="carrousel-statut-deactivate"
              // disabled={filter.activ ? true : false}
              checked={filter.deactivated ? true : false}
              onChange={(e) =>
                filterChangeHandle("deactivated", e.target.checked)
              }
            />
            <label htmlFor="carrousel-statut-deactivate">Désactivés</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
